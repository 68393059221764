import React, {useCallback, useEffect} from 'react';
import {getUsersByReportApi} from '../Utilities/reportsApis';
import {useOutletContext} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {addAllUsersByReport} from '../../redux/adminReducer';
import classes from './UserListComponent.module.css';
import CloseIcon from '@mui/icons-material/Close';

const UsersListComponent = ({report, handleClose}) => {
	const googleIdToken = useOutletContext(); // <-- access context value
	const dispatch = useDispatch();
	const usersData = useSelector(
		(state) => state.AdminPageData.allUsersByReport
	);
	const fetchUsersByReport = useCallback(
		async (report) => {
			const result = await getUsersByReportApi(report?.ID, googleIdToken);
			dispatch(addAllUsersByReport(result));
		},
		[dispatch, googleIdToken]
	);
	useEffect(() => {
		fetchUsersByReport(report);
	}, [fetchUsersByReport, report]);
	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div className={classes.leftHeader}>Users</div>
				<div className={classes.rightHeader}>
					<CloseIcon
						className={classes.closeButton}
						onClick={handleClose}
					/>
				</div>
			</div>
			<div className={classes.UserList}>
				<div className={classes.singleElementStyle}>
					<div style={{flex: 1, textAlign: 'center', fontWeight: 'bold'}}>
						S.No
					</div>
					<div style={{flex: 2, textAlign: 'center', fontWeight: 'bold'}}>
						Email
					</div>
					<div style={{flex: 1, textAlign: 'center', fontWeight: 'bold'}}>
						Name
					</div>
				</div>
				{usersData?.map((user, index) => {
					return (
						<div
							key={user?.USER_ID}
							className={classes.singleElementStyle}
						>
							<div style={{flex: 1, textAlign: 'center'}}>{index + 1}</div>
							<div style={{flex: 2, textAlign: 'center'}}>{user?.EMAILID}</div>
							<div style={{flex: 1, textAlign: 'center'}}>
								{user?.FIRSTNAME}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

UsersListComponent.propTypes = {
	report: PropTypes.object,
	handleClose: PropTypes.func,
};
export default UsersListComponent;
