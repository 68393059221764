import {Logout} from '@mui/icons-material'; // Import icons
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import {motion} from 'framer-motion'; // For animations
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Logo from '../../components/Userprofile/logoImage'; // Assuming Logo component exists

const HomePage = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state) => state.PowerBi.user);
  const fullName = [user?.FIRSTNAME, user?.MIDDLENAME, user?.LASTNAME]
    .filter(Boolean) // Remove undefined or null values
    .map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()) // Capitalize first letter
    .join(' '); // Combine into a single string

  const PMS_ROLE = user?.PMS_ACCESS;

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    navigate('/reports'); // Redirect to the main page or login page
  };

  // Create a custom theme for a more vibrant and professional look
  const theme2 = createTheme({
    palette: {
      primary: {
        main: '#FFA500', // Orange for a bold and energetic feel
      },
      secondary: {
        main: '#000000', // Black for contrast
      },

      text: {
        primary: '#FFFFFF', // White text for clarity
        secondary: '#BBBBBB', // Lighter gray for secondary text
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: '3rem',
        letterSpacing: '0.1rem',
      },
      h2: {
        fontWeight: 500,
        fontSize: '2.2rem',
        letterSpacing: '0.05rem',
      },
      button: {
        fontWeight: 600,
        fontSize: '1.1rem',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0px 10px 40px rgba(255, 165, 0, 0.5)', // Orange hover shadow
            },
            borderRadius: '20px',
            padding: '1.2rem 2.5rem',
            textTransform: 'none',
          },
        },
      },
    },
  });

  return (
    <>
      <Logo></Logo>
      <Grid
        item
        xs={isMobile ? 6 : 3}
        sx={{
          position: 'absolute', // Position it absolutely relative to the parent
          top: 10, // Align to the top
          right: 10, // Align to the right
          display: 'flex',
          justifyContent: 'flex-end',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {transform: 'scale(1.1)'}, // Hover scale effect
        }}
      >
        <IconButton
          color='inherit'
          sx={{
            p: 1,
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)', // Glow effect
              transform: 'scale(1.1)',
            },
          }}
          onClick={handleLogout}
        >
          <Logout />
        </IconButton>
      </Grid>

      <ThemeProvider theme={theme2}>
        {/* NavBar Code directly added here */}

        {/* Remaining content of HomePage */}
        <Box
          sx={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundColor: theme2.palette.background.default,
            color: 'black',
            padding: '2rem',
          }}
        >
          {/* Animated introduction text */}
          <motion.div
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 1}}
          >
            <Typography variant='h1' align='center' gutterBottom>
              Hi {fullName}
            </Typography>
          </motion.div>

          {/* Question text */}
          <motion.div
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: 1, duration: 1}}
          >
            <Typography variant='h2' align='center' gutterBottom>
              Which tool would you like to explore today?
            </Typography>
          </motion.div>

          {/* Buttons for navigation */}
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay: 2, duration: 1}}
            style={{
              display: 'flex',
              gap: '3rem',
              marginTop: '3rem',
              flexDirection: 'row',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => handleNavigation('/calibration-dashboard')}
              sx={{
                boxShadow: '0px 10px 30px rgba(255, 165, 0, 0.6)', // Orange shadow
              }}
            >
              Calibration Dashboard
            </Button>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              onClick={() => handleNavigation('/increment-matrix')}
              sx={{
                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)', // Black shadow for contrast
              }}
            >
              Increment Matrix Dashboard
            </Button>
            {/* <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => handleNavigation('/pms-analysis')}
              sx={{
                boxShadow: '0px 10px 30px rgba(255, 165, 0, 0.6)', // Orange shadow
              }}
            >
              PMS Analysis Dashboard
            </Button> */}
          </motion.div>

          {/* Footer with subtle animation */}
          {/* <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 3, duration: 1}}
          style={{position: 'absolute', bottom: '2rem', textAlign: 'center'}}
        >
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#FFA500', // Orange on hover
              },
            }}
          >
            OneData
          </Typography>
        </motion.div> */}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default HomePage;
