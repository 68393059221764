import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {format} from 'date-fns'; // Ensure you're importing the format function from date-fns or a similar library
import React, {useState} from 'react';

const PersonDetailModal = ({open, onClose, person}) => {
  const [openRevenueUtilization, setOpenRevenueUtilization] = useState(false);

  const formatDate = (dateString) => {
    // Handle the special case for '01-01-1900'
    if (dateString === '01-01-1900') {
      return '';
    }

    try {
      // Parse the date
      const parsedDate = new Date(dateString);

      // Check if the date is invalid
      if (!dateString || isNaN(parsedDate)) {
        return ''; // Save "N/A" for invalid dates
      }

      // Return formatted date
      return format(parsedDate, 'MMMM d, yyyy');
    } catch {
      // Fallback to "N/A" in case of any errors
      return '';
    }
  };

  const formattedJoiningDate = formatDate(person?.['Date of Joining']);
  const getCurrencySymbol = (geo) => {
    switch (geo) {
      case 'US':
        return '$';
      case 'CN':
        return '$'; // Yuan symbol for China
      case 'UK':
        return ' €'; // Symbol for the UK
      default:
        return '';
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '1.7rem', // Increased font size for better readability
          fontWeight: '600', // Slightly lighter weight for a modern feel
          color: '#F7901D', // Updated to the specified color
          letterSpacing: '0px', // Adding letter spacing for better clarity
          borderBottom: '2px solid #e0e0e0', // Adding a subtle border at the bottom
          textTransform: 'uppercase', // Making the title uppercase for emphasis
          backgroundColor: '#f5f5f5', // Adding a light background color for contrast
          borderRadius: '8px', // Soft rounded corners for a more modern look
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Adding a slight shadow for depth
          fontFamily: '"Nanum Myeongjo", serif', // Added font family
        }}
      >
        Employee Increment Details
        <Box
          sx={{
            position: 'absolute',
            top: '2px', // Distance from the top of the dialog
            right: '25px', // Distance from the right of the dialog
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
            alignItems: 'flex-start', // Align the items to the left
            padding: '0px', // Reduced padding for smaller box
            gap: '0px', // No gap between the items
          }}
        >
          {/* First pair: Conversion Factor with Checkbox */}
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{display: 'flex', fontFamily: '"Nanum Myeongjo", serif'}}
      >
        {' '}
        {/* Added font family */}
        <Grid container spacing={2}>
          {/* Left Column for Employee Detail Card */}
          <Grid item xs={12} sm={3} sx={{position: 'sticky', top: 0, mt: 2}}>
            <Card
              sx={{
                borderRadius: '16px',
                boxShadow: 3,
                maxWidth: '300px',
                margin: 'auto',
              }}
            >
              <CardContent>
                <Typography
                  variant='h7'
                  gutterBottom
                  sx={{
                    color: '#F7901D', // Updated to the specified color
                    fontWeight: 'bold',
                    textAlign: 'center',
                    width: '100%',
                    display: 'block',
                    fontFamily: '"Nanum Myeongjo", serif', // Added font family
                    fontSize: '1.3rem',
                  }}
                >
                  Employee Details
                </Typography>
                {[
                  {label: 'Name', value: person?.['Display Name']},
                  {label: 'Emp ID', value: person?.['Emp ID']},
                  {label: 'Geo', value: person?.['Geo']},
                  {
                    label: 'Currency',
                    value: person?.['Currency'],
                  },

                  {label: 'Designation', value: person?.['Designation']},
                  {label: 'Department', value: person?.['Department']},
                  {label: 'Joining Date', value: formattedJoiningDate},
                  {
                    label: 'Experience (Tiger)',
                    value: `${person?.['HR Years of Exp @ Tiger']} years`,
                  },
                  {
                    label: 'Experience',
                    value: `${person?.['HR Years of Exp']} years`,
                  },
                  {label: 'Current CP', value: person?.['Current Manager']},
                  {
                    label: 'BU HEAD',
                    value: `${person?.['Final BU Head Tagging']}`,
                  },

                  {label: 'Tagging', value: person?.['Tagging']},
                  {label: 'Review CP', value: person?.['Review CP']},
                  {
                    label: 'Last Review Date',
                    value: person?.['Current_Review_Cycle'],
                  },
                  {
                    label: 'Last Promoted Date',
                    value:
                      person?.['Last Promoted Date'] === '0'
                        ? 'Never Promoted'
                        : person?.['Last Promoted Date'],
                  },
                ].map((item, idx) => (
                  <Typography
                    key={idx}
                    variant='caption'
                    sx={{
                      display: 'block',
                      fontWeight: 'normal',
                      marginBottom: '-3px',
                      fontSize: '0.9rem',
                      fontFamily: '"Nanum Myeongjo", serif',
                    }}
                  >
                    <strong>{item.label}:</strong>{' '}
                    {item.label === 'Name' ? (
                      <span style={{fontSize: '1.0rem', fontWeight: 'bold'}}>
                        {item.value || 'N/A'}
                      </span>
                    ) : (
                      item.value || 'N/A'
                    )}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>

          {/* Right Column for Tables (scrollable) */}
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              overflowY: 'auto',
              overflowX: 'auto',
              height: 'calc(100vh - 190px)',

              paddingRight: '20px',
              paddingTop: 2,
              paddingBottom: 2,
              fontFamily: '"Nanum Myeongjo", serif', // Added font family
              '&::-webkit-scrollbar': {
                width: '10px', // Set the width of the scrollbar
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1', // Background of the scrollbar track
                borderRadius: '10px', // Rounded corners for track
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Color of the scrollbar thumb
                borderRadius: '10px', // Rounded corners for thumb
                border: '3px solid #f1f1f1', // Border to separate thumb from track
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555', // Darken the thumb color on hover
              },
            }}
          >
            <div>
              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: 3,
                  borderRadius: '8px',
                  boxShadow: 3,
                  overflowX: 'auto',
                  marginTop: '17px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                      {[
                        'Phase',
                        'Total Compensation',
                        'Total Base',
                        'Total Bonus',
                        'Cash Bonus',
                        'ESOP Bonus',
                        'Blend',
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            color: '#F7901D',
                            padding: '12px 16px',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            fontFamily: '"Nanum Myeongjo", serif',
                            fontSize: '1rem', // Increased font size for headers
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{backgroundColor: '#fafafa'}}>
                      {[
                        'Current Comp',
                        person?.['Current_yr_Total_Comp_Salary'],
                        person?.['Current_yr_Base_Salary'],
                        person?.['Current_yr_Bonus_Salary'],
                        person?.['Bonus 50% Cash - USD'],
                        person?.['Bonus 50% ESOP - USD'],
                        person?.['Current_yr_Bonus_Blend_Salary'],
                      ].map((value, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            padding: '12px 16px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            fontSize: '0.9rem',
                            fontWeight: 'normal',
                            color: '#333',
                            fontFamily: '"Nanum Myeongjo", serif',
                          }}
                        >
                          {typeof value === 'number'
                            ? Math.floor(value)
                            : value || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#fafafa'}}>
                      {[
                        'Proposed Comp by Review Committee',
                        person?.['Proposed Total Comp'],
                        person?.['Proposed Total Base'],
                        person?.['Proposed Total Bonus'],
                        person?.['Proposed Total Cash'],
                        person?.['Proposed Total ESOP'],
                        person?.['Proposed Blend'],
                      ].map((value, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            padding: '12px 16px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            fontSize: '0.9rem',
                            fontWeight: 'normal',
                            color: '#333',
                            fontFamily: '"Nanum Myeongjo", serif',
                          }}
                        >
                          {typeof value === 'number'
                            ? Math.floor(value)
                            : value || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Increment Units Table */}
              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: 3,
                  borderRadius: '8px',
                  boxShadow: 3,
                  overflowX: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                      {[
                        'Final Rating',
                        'Final Promotion',
                        'Final Revised Total Comp',
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            color: '#F7901D', // Updated to the specified color
                            padding: '12px 16px',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            fontSize: '1rem',
                            fontFamily: '"Nanum Myeong jo", serif', // Added font family
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{backgroundColor: '#fafafa'}}>
                      {[
                        person?.['Final Rating'],
                        person?.['Final Promotion'],
                        person?.['Final Total Comp']
                          ? Math.floor(person['Final Total Comp'])
                          : person?.['Final Total Comp'],
                      ].map((value, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            padding: '12px 16px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            fontSize: '0.9rem',
                            color: '#333',
                            fontFamily: '"Nanum Myeongjo", serif', // Added font family
                          }}
                        >
                          {value || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Revenue utlisation */}
              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: 3,
                  borderRadius: '8px',
                  boxShadow: 3,
                  overflowX: 'auto',
                }}
              >
                <Table sx={{tableLayout: 'fixed', width: '100%'}}>
                  <TableHead>
                    <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                      {[
                        'Current Review Year Revenue',
                        'Total Review Months',
                        'Billed Utilization',
                        'Non Bench Utilization',
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            color: '#F7901D',
                            padding: '12px 16px',
                            whiteSpace: 'nowrap',
                            fontSize: '1rem',
                            textAlign: 'center',
                            fontFamily: '"Nanum Myeong jo", serif',
                            width: '25%', // Ensures equal column distribution
                            minWidth: '200px', // Prevents columns from shrinking too much
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{backgroundColor: '#fafafa'}}>
                      {[
                        person?.['Current_Year_Revenue (Jan - Dec)'],
                        person?.['Total Months (Jan - Dec)'],
                        person?.['Billed Util (Jan - Dec)'],
                        person?.['Non Bench Util (Jan - Dec)'],
                      ].map((value, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            padding: '12px 16px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            fontSize: '1rem',
                            fontWeight: 'normal',
                            color: '#333',
                            fontFamily: '"Nanum Myeongjo", serif',
                            width: '25%', // Ensures equal column width
                            minWidth: '200px',
                          }}
                        >
                          {value || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Multiplication Table */}
              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: 3,
                  borderRadius: '8px',
                  boxShadow: 3,
                  overflowX: 'auto',
                }}
              >
                <Table sx={{tableLayout: 'fixed', width: '100%'}}>
                  <TableHead>
                    <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                      {[
                        'Multiplication Factors-Increment',
                        'Multiplication Factor – Bonus',
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            color: '#F7901D',
                            padding: '12px 16px',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            fontSize: '0.95rem',
                            fontFamily: '"Nanum Myeong jo", serif',
                            width: '25%', // Ensures equal column width
                            minWidth: '200px',
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{backgroundColor: '#fafafa'}}>
                      {[
                        person?.['Increment Multiplication Factor'],
                        person?.['Bonus Multiplication Factor'],
                      ].map((value, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            padding: '12px 16px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            fontSize: '0.9rem',
                            fontWeight: 'normal',
                            color: '#333',
                            fontFamily: '"Nanum Myeongjo", serif',
                            width: '25%', // Ensures equal column width
                            minWidth: '200px',
                          }}
                        >
                          {value || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center'}}>
        <Button
          onClick={onClose}
          color='primary'
          variant='contained'
          sx={{
            textTransform: 'none',
            borderRadius: '25px',
            padding: '4px 10px',
            boxShadow: 2,
            '&:hover': {boxShadow: 3},
            fontFamily: '"Nanum Myeongjo", serif', // Added font family
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonDetailModal;
