import React, {useState, useEffect} from 'react';
import {Grid, CircularProgress, Button} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import CustomModal from '../../components/Modal/CustomModal';
import classes from './UserDetailsReports.module.css';
import {
	getUsers,
	getReportsByUser,
	addLoading,
	addReportsLoading,
} from '../../redux/adminReducer';
import {getAllUsersRefactoredApi} from '../../components/Utilities/apis/getAllUsers';
import getUserReports from '../../components/Utilities/v2Apis/getUserReports';
import CustomAlert from '../../components/Alerts/CustomAlert';
import Error from '../../components/ErrorPage/Error';
import UserReportForm from './UserReportForm/UserReportForm';
import AddReportToUser from './AddReportsPopUp';
import SearchAutoCompleteUsers from './SearchAutoCompleteUsers';

const UserDetailsReports = () => {
	const dispatch = useDispatch();
	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [text, setText] = useState('');
	const [selectedUser, setSelectedUser] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

	const rowdata = useSelector((state) => state.AdminPageData.usersDetails);
	const isLoading = useSelector((state) => state.AdminPageData.isLoading);

	const getData = () => {
		dispatch(addLoading(true));
		getAllUsersRefactoredApi().then((data) => {
			if (data.length === 0) {
				setError(true);
				setText('Something went wrong');
			} else {
				setError(false);
				dispatch(getUsers(data));
			}
			dispatch(addLoading(false));
		});
	};

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const handleSelectedUser = (user) => {
		setSelectedUser(user);
	};

	const handleUserChange = (e, user) => {
		e.preventDefault();
		setSelectedUser(user ?? {});
		if (user?.userId) {
			dispatch(addReportsLoading(true));
			getUserReports(user?.userId).then((data) => {
				dispatch(getReportsByUser(data));
				dispatch(addReportsLoading(false));
			});
		} else {
			dispatch(getReportsByUser({}));
			dispatch(addReportsLoading(false));
		}
	};

	// Open and close modal handlers
	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	// Fetch data on component mount
	useEffect(() => {
		dispatch(addLoading(true));
		getAllUsersRefactoredApi().then((data) => {
			if (data.length === 0) {
				setError(true);
				setText('Something went wrong');
			} else {
				setError(false);
				dispatch(getUsers(data));
			}
			dispatch(addLoading(false));
			dispatch(getReportsByUser({}));
		});
	}, [dispatch]);

	const renderContent = () => {
		if (error)
			return (
				<Error
					text={text}
					handleError={getData}
				/>
			);
		if (isLoading)
			return (
				<CircularProgress
					style={{position: 'fixed', color: 'orange', top: '50%', right: '50%'}}
				/>
			);
		return (
			<Grid
				container
				className={classes.container}
				sx={{
					'&::-webkit-scrollbar': {
						width: 6,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#F4F4F4',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#FD9327',
						borderRadius: 1,
					},
				}}
			>
				<CustomAlert
					alert={alert}
					message={alertMessage}
					handleClose={handleAlertClose}
					success={success}
				/>
				<Grid
					item
					xs={4}
					md={4}
					xl={4}
				>
					<SearchAutoCompleteUsers
						handleUserChange={handleUserChange}
						rowdata={rowdata}
						selectedUser={selectedUser}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					md={6}
					xl={6}
				>
					<Button
						onClick={handleOpenModal}
						style={{
							backgroundColor: '#F7901D',
							color: 'white',
							borderRadius: '1rem',
							textTransform: 'none',
							width: '50%',
						}}
					>
						Add/Update User
					</Button>
				</Grid>
				<CustomModal
					open={isModalOpen}
					onClose={handleCloseModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<UserReportForm
							formdata={selectedUser}
							userId={selectedUser?.userId}
							handleAlert={handleAlert}
							handleSelectedUser={handleSelectedUser}
							handleUserChange={handleUserChange}
							rowdata={rowdata}
							handleCloseModal={handleCloseModal}
						/>
					</>
				</CustomModal>
				<AddReportToUser userId={selectedUser?.userId} />
			</Grid>
		);
	};

	return renderContent();
};

export default UserDetailsReports;
