import {Logout} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import axios from 'axios';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Logo from '../../components/Userprofile/logoImage';
import config from '../../config';
import './FileList.css';

const FileList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate function
  const token = localStorage.getItem('googleIdToken');

  const handleDownloadTable = async (tableName, specificColumns = null) => {
    setLoading(true);
    setError(null);

    try {
      const params = {tableNames: [tableName]};
      if (specificColumns) {
        params.columns = JSON.stringify({[tableName]: specificColumns});
      }

      const response = await axios.get(`${config.apiURL}/download-tables`, {
        headers: {Authorization: `Bearer ${token}`}, // Fix here: Add backticks for template literal
        params,
        responseType: 'blob', // Ensure the response is treated as binary data
      });

      // Handle the response (e.g., download the file, etc.)
      // You can process the response here, for example:
      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatch =
        contentDisposition && contentDisposition.match(/filename="(.+)"/);
      const fileName = fileNameMatch ? fileNameMatch[1] : `${tableName}.xlsx`; // Fallback to table name

      // Create a Blob URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary anchor element for downloading
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Use the filename from the server
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the temporary element and URL
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      setError('Error downloading tables');
      console.error('Error downloading tables:', e);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate('/calibration-dashboard');
  };

  return (
    <>
      <Logo />

      <div className='file-upload-container'>
        <div className='header'>
          <h1>File Download System</h1>
        </div>

        <div className='header-buttons'>
          <IconButton
            color='inherit'
            sx={{
              p: 1,
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)', // Glow effect
                transform: 'scale(1.1)',
              },
            }}
            onClick={handleGoBack}
          >
            <Logout />
          </IconButton>
        </div>

        <div className='content'>
          <div className='file-list' style={{width: '33%'}}>
            <h2>File Uploaded</h2>
            <ul>
              <li
                className='file-item'
                onClick={() => handleDownloadTable('STG.STG_PMS_HR_ONSHORE')}
              >
                HR Onshore Comp Master
              </li>

              <li
                className='file-item'
                onClick={() => handleDownloadTable('STG.STG_PMS_PY')}
              >
                Perform Yard Employee Dump
              </li>
              <li
                className='file-item'
                onClick={() => handleDownloadTable('STG.STG_PMS_PY_BASE')}
              >
                PY Completion Status Source
              </li>
              <li
                className='file-item'
                onClick={() => handleDownloadTable('STG.STG_PMS_UTILIZATION')}
              >
                Utilisation Sheet
              </li>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('STG.STG_PMS_CRITICALITY_PROMOTION')
                }
              >
                Perform Yard Promotion and Criticality
              </li>
              <li
                className='file-item'
                onClick={() => handleDownloadTable('TGT.LOOKUP_PMS_DEPT')}
              >
                Grouping Matrix
              </li>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.LOOKUP_PMS_DESIGNATION')
                }
              >
                Designation Eligibility
              </li>
              <li
                className='file-item'
                onClick={() => handleDownloadTable('TGT.LOOKUP_PMS_BU_BUDGET')}
              >
                BU Budget
              </li>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.PMS_INCREMENTAL_MATRIX_US')
                }
              >
                Increment Matrix
              </li>
            </ul>
          </div>

          <div className='file-list' style={{width: '33%'}}>
            <h2>Backend Tables</h2>

            <ul>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.VW_PMS_CALIBRATION_MASTER')
                }
              >
                CALIBRATION_MASTER
              </li>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.PMS_CALIBRATION_MASTER_INPUT')
                }
              >
                PMS Calibration Master Input
              </li>
              <li
                className='file-item'
                onClick={() => handleDownloadTable('TGT.VW_PMS_INCR_MATRIX_US')}
              >
                Increment Matrix View
              </li>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.PMS_INCREMENTAL_MATRIX_US_INPUT')
                }
              >
                Increment Matrix Input
              </li>
            </ul>
          </div>
          <div className='file-list' style={{width: '33%'}}>
            <h2>Automated Lists</h2>
            <ul>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.PMS_ELIGIBILITY', [
                    'Emp ID',
                    'Display Name',
                    'First Name',
                    'Last Name',
                    'Nickname',
                    '[Group]',
                    'Email',
                    'lkp_PY_Email',
                    'PY_Email_Check',
                    'Job Title',
                    'lkp_PY_Job_Title',
                    'PY_Job_Title_Check',
                    'Hire Date',
                    'lkp_PY_Hire_Date',
                    'Manager',
                    'PY_DOJ_Check',
                    'lkp_PY_Manager',
                    'PY_Manager_Check',
                    'Department',
                    'lkp_PY_Department',
                    'PY_Dept_Check',
                  ])
                }
              >
                Intervention Checks
              </li>
              <li
                className='file-item'
                onClick={() =>
                  handleDownloadTable('TGT.VW_PMS_ELIGIBILITY', [
                    'Display Name',
                    'First Legal Name (As on ID)',
                    'Nickname',
                    'Last Legal Name (As on ID)',
                    'Email',
                    'Emp ID',
                    'Job Title',
                    'Hire Date',
                    '[Group]',
                    'Manager',
                    'Department',
                    'lkp_PY_Email',
                    'PY_Email_Check',
                    'lkp_PY_Job_Title',
                    'PY_Job_Title_Check',
                    'lkp_PY_Hire_Date',
                    'PY_DOJ_Check',
                    'lkp_PY_Manager',
                    'PY_Manager_Check',
                    'lkp_PY_Department',
                    'PY_Dept_Check',
                  ])
                }
              >
                Eligibility List
              </li>
            </ul>
          </div>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p className='error'>{error}</p>}
      </div>
    </>
  );
};

export default FileList;
