import React, {useEffect, useState} from 'react';
import {
	Grid,
	CircularProgress,
	Checkbox,
	Typography,
	FormControlLabel,
	Button,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import getUserReports from '../../../components/Utilities/v2Apis/getUserReports';
import userToReportsMapping from '../../../components/Utilities/v2Apis/userToReportMapping';
import {addReportsLoading, getReportsByUser} from '../../../redux/adminReducer';

export default function MultiSelectComponentUserReport(props) {
	const dispatch = useDispatch();
	const {userReports, handleAlert, userId} = props;
	const [SelectedReports, setSelectedReports] = useState({});
	const [allReports, setAllReports] = useState({});
	const isReportsLoading = useSelector(
		(state) => state.AdminPageData.isReportsLoading
	);

	const handleChange = (groupname, value) => {
		let list = [];
		if (SelectedReports?.[groupname]) {
			if (
				SelectedReports?.[groupname]?.some((report) => report?.ID === value?.ID)
			) {
				list = SelectedReports?.[groupname].filter(
					(item) => item?.ID !== value?.ID
				);
			} else {
				list = [...SelectedReports?.[groupname], value];
			}
		} else {
			list = [value];
		}
		setSelectedReports((prev) => ({
			...prev,
			[groupname]: list,
		}));
	};

	const handleSelectAll = (groupname, reports, isChecked) => {
		const newSelection = isChecked ? reports : [];
		setSelectedReports((prev) => ({
			...prev,
			[groupname]: newSelection,
		}));
	};

	const findAddedReportsORRemovedReports = (reports, reportsChanged) => {
		let result = [];
		Object.entries(reportsChanged)?.map(([key, value]) => {
			return value?.filter((newReport) => {
				if (
					!reports?.[key]?.some((prevReport) => prevReport.ID === newReport.ID)
				) {
					result.push(newReport);
				}
				return null;
			});
		});
		return result;
	};

	const handleAddReports = () => {
		const addedReports = findAddedReportsORRemovedReports(
			userReports,
			SelectedReports
		);
		const removedReports = findAddedReportsORRemovedReports(
			SelectedReports,
			userReports
		);

		userToReportsMapping({
			addedReports,
			removedReports,
			userId,
		}).then((res) => {
			handleAlert(res);
			userId &&
				getUserReports(userId).then((data) => {
					dispatch(getReportsByUser(data));
				});
		});
	};

	useEffect(() => {
		setSelectedReports(userReports);
	}, [userReports]);

	useEffect(() => {
		dispatch(addReportsLoading(true));
		getUserReports().then((result) => {
			setAllReports(result);
		});
		dispatch(addReportsLoading(false));
	}, [dispatch]);

	return isReportsLoading ? (
		<Grid
			container
			style={{display: 'flex', justifyContent: 'center'}}
		>
			<CircularProgress style={{color: 'orange'}} />
		</Grid>
	) : (
		<Grid container>
			<Grid
				container
				style={{display: 'flex', justifyContent: 'right'}}
			>
				<Grid
					item
					style={{margin: '0.5rem', display: 'flex', justifyContent: 'right'}}
					xs={4}
					md={4}
					xl={4}
				>
					<Button
						style={{
							color: 'white',
							cursor: 'pointer',
							padding: '0.2rem',
							textTransform: 'none',
							backgroundColor: '#F7901D',
							borderRadius: '1rem',
							width: '50%',
						}}
						onClick={handleAddReports}
					>
						Add Reports
					</Button>
				</Grid>
			</Grid>
			<Grid
				container
				style={{border: '1px solid #f7901d'}}
			>
				{Object.entries(allReports)?.map(([key, value]) => {
					const allSelected = SelectedReports[key]?.length === value?.length;
					const someSelected = SelectedReports[key]?.length > 0 && !allSelected;

					return (
						<Grid
							item
							xs={2}
							md={2}
							xl={2}
							key={key}
							style={{border: '1px solid #f7901d'}}
						>
							<Typography
								style={{
									borderBottom: '1px solid #f7901d',
									background:
										'linear-gradient(155deg, #f8f5f0 0%, #fce3c7 100%, #f7901d 100%)',
									display: 'flex',
									justifyContent: 'left',
									alignItems: 'center',
									paddingLeft: '0.25rem', // Reduced padding
								}}
							>
								<FormControlLabel
									control={
										<Checkbox
											indeterminate={someSelected}
											checked={allSelected}
											onChange={(e) =>
												handleSelectAll(key, value, e.target.checked)
											}
										/>
									}
									label={
										<span
											style={{
												fontSize: '0.7rem',
												textTransform: 'uppercase',
											}}
										>
											{key}
										</span>
									}
								/>
							</Typography>
							<Grid
								container
								spacing={0} // Reduce spacing between items
							>
								{value?.map((reportName) => {
									return (
										<Grid
											item
											key={reportName?.ID}
											style={{
												display: 'flex',
												height: '2rem',
												alignItems: 'center',
											}}
											xs={12}
										>
											<Typography
												style={{
													display: 'flex',
													justifyContent: 'flex-start',
													paddingLeft: '0.25rem', // Reduced padding
													fontSize: '0.7rem',
												}}
											>
												<FormControlLabel
													key={reportName?.ID}
													style={{
														fontSize: '0.7rem',
													}}
													control={
														<Checkbox
															checked={
																SelectedReports[key]?.some(
																	(report) => report?.ID === reportName?.ID
																) || false
															}
															onChange={() => handleChange(key, reportName)}
															style={{fontSize: '0.7rem'}}
														/>
													}
													label={
														<span
															style={{
																fontSize: '0.7rem',
																textTransform: 'uppercase',
															}}
														>
															{reportName?.NAME}
														</span>
													}
												/>
											</Typography>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
}
