import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {StyledEngineProvider} from '@mui/material/styles';
import classes from './ReportsTable.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Group} from '@mui/icons-material';

const ReportsTable = ({
	editData,
	deleteData,
	data: rowdata,
	getUsersByReport,
}) => {
	const [rows, setRows] = useState([]);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('NAME');

	useEffect(() => {
		if (rowdata && rowdata?.length > 0) {
			setRows(rowdata);
		}
	}, [rowdata]);

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		const newOrder = isAsc ? 'desc' : 'asc';
		const sortedRows = [...rows].sort((a, b) => {
			if (a[property] < b[property]) {
				return newOrder === 'asc' ? -1 : 1;
			}
			if (a[property] > b[property]) {
				return newOrder === 'asc' ? 1 : -1;
			}
			return 0;
		});
		setOrder(newOrder);
		setOrderBy(property);
		setRows(sortedRows);
	};
	return (
		<StyledEngineProvider injectFirst>
			<Box>
				<TableContainer
					component={Paper}
					className={classes.table}
					sx={{
						'&::-webkit-scrollbar': {
							width: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#F4F4F4',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgb(37, 200, 241)',
						},
					}}
					style={{minWidth: 650, maxHeight: (window.screen.height * 50) / 100}}
				>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: 'rgb(37, 200, 241)',
									fontSize: '14px',
									color: '#312E2D',
									fontWeight: 'bold',
								}}
							>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									Id
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									<TableSortLabel
										active={orderBy === 'NAME'}
										direction={orderBy === 'NAME' ? order : 'asc'}
										onClick={() => handleRequestSort('NAME')}
									>
										Report Name
									</TableSortLabel>
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									<TableSortLabel
										active={orderBy === 'GroupName'}
										direction={orderBy === 'GroupName' ? order : 'asc'}
										onClick={() => handleRequestSort('GroupName')}
									>
										Group
									</TableSortLabel>
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									Report ID
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									WorkSpace ID
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									Date Created
								</TableCell>
								<TableCell
									sx={{fontWeight: 'bold', padding: '2.5px'}}
									align='center'
								>
									Actions
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{rows?.length > 0 &&
								rows?.map((row, id) => (
									<TableRow
										key={row?.ID}
										sx={{
											'&:last-child td, &:last-child th': {border: 0},
											'&:nth-of-type(even)': {
												backgroundColor: 'white',
											},
											'&:nth-of-type(odd)': {
												backgroundColor: 'rgb(200, 237, 246)',
											},
										}}
									>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{id + 1}
										</TableCell>
										<TableCell
											component='th'
											scope='row'
											align='center'
											sx={{padding: '2.5px'}}
										>
											{row?.NAME}
										</TableCell>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{row?.GroupName}
										</TableCell>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{row?.PBIREPORTID}
										</TableCell>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{row?.PBIWORKSPACEID}
										</TableCell>
										<TableCell
											align='center'
											sx={{padding: '2.5px'}}
										>
											{row?.CREATEDAT
												? moment(row.CREATEDAT).format('YYYY-MM-DD HH:mm:ss')
												: ''}
										</TableCell>
										<TableCell
											align='center'
											sx={{
												padding: '2.5px',
											}}
										>
											<Group
												onClick={() => getUsersByReport(row)}
												className={classes.groupIcon}
											/>
											<EditIcon
												className={classes.editIcon}
												onClick={() => editData(row)}
											/>
											<DeleteOutlineIcon
												className={classes.deleteIcon}
												onClick={() => deleteData(row)}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</StyledEngineProvider>
	);
};

export default ReportsTable;
