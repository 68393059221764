import {
  Box,
  Checkbox,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';

import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import PersonDetailModal from './EmployeeDetailsModal'; // Import the modal component

const TableComponent = ({
  filteredData,
  columnNames,
  columnVisibility,
  frozenColumn,
  page,
  rowsPerPage,
  IncrementStatus,
  handleSelectAll,
  handleRowSelect,
  handleVpInitialValueChange,
  handleVpChange,
  handleChangePage,
  handleChangeRowsPerPage,
  handleHRValueChange,
  handleincrement,
  handlecomp,
  handleFinalVpAprroval,
  FinalVpAprroval,
  handleincrementper,
  increment,
  incrementper,
  comp,
  handlehrcomments,
  HrComments,
  HRValue,
  selectedRows,
  VpInitialValues,
  VpComments,
  handleFreezePane,
  handleFilterClick,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleDisplayNameClick = (person) => {
    setSelectedPerson(person);
    setModalOpen(true);
  };
  const user = useSelector((state) => state.PowerBi.user);

  const PMS_ROLE = user?.PMS_ACCESS;

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPerson(null);
  };

  return (
    <Box p={2} sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: {
            xs: 'calc(100vh - 100px)', // For extra small screens (mobile devices)
            sm: 'calc(100vh - 120px)', // For small screens (tablet)
            md: 'calc(100vh - 120px)', // For medium screens (laptops)
            lg: 'calc(100vh - 180px)', // For large screens (desktops)
            xl: 'calc(100vh - 180px)', // For extra large screens
          },
          overflow: 'auto',
          boxShadow: 3,
          borderRadius: 2,
          position: 'relative',
          backgroundColor: '#fff7e1',
          fontFamily: '"Nanum Myeongjo", serif',
          '&::-webkit-scrollbar': {
            width: '10px', // Width of the scrollbar
            height: '10px', // Height of the horizontal scrollbar
          },
          '&::-webkit-scrollbar-track': {
            background: '#f0f0f0', // Track color
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#7b7b7b', // Thumb color
            borderRadius: '10px',
            border: '2px solid #f0f0f0', // Border around the thumb for contrast
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#4f4f4f', // Hover color for thumb
          },
          '&::-webkit-scrollbar-corner': {
            background: '#f0f0f0', // Bottom right corner color
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                align='center'
                sx={{
                  fontWeight: 'bold',
                  background: 'linear-gradient(90deg, #F7901D, #FFB547)',
                  color: 'red',
                  textTransform: 'capitalize',
                  fontSize: '0.85rem',
                  fontFamily: '"Nanum Myeongjo", serif',
                  padding: '10px',
                  width: '20px',
                  border: '1px solid #d69e2e',
                  position: 'sticky',
                  top: 0,
                  zIndex: 30,
                  left: 0, // Freeze checkbox column by fixing it to the left
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #FFB547, #F7901D)',
                  },
                }}
              >
                <Checkbox
                  checked={selectedRows.size === filteredData.length}
                  onChange={handleSelectAll}
                  size='small'
                  sx={{
                    transform: 'scale(0.8)', // Scale down the checkbox
                    padding: 0.5, // Reduce padding if needed
                    color: 'white',
                    '&.Mui-checked': {
                      color: '#FFF9F5',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: '1rem', // Reduce the icon size further
                    },
                  }}
                />
              </TableCell>

              {columnNames.map(
                (column, index) =>
                  columnVisibility[column] !== false && (
                    <TableCell
                      key={column}
                      align='center'
                      sx={{
                        fontWeight: 'bold',
                        background: 'linear-gradient(90deg, #F7901D, #FFB547)',
                        color: '#000000 !important',
                        textTransform: 'capitalize',
                        fontSize: '0.9rem',
                        padding: '10px',

                        fontFamily: '"Nanum Myeongjo", serif',
                        textAlign: 'center',
                        minWidth:
                          column === 'Latest Total Comp (2023) - USD'
                            ? '80px'
                            : column === 'Final Total Comp'
                            ? '30px'
                            : column === 'Emp ID'
                            ? '20px'
                            : column === '% Increase of Proposed Total Comp'
                            ? '80px'
                            : column === 'Indiv. Total Comp Incr. (USD)'
                            ? '80px'
                            : column === 'Proposed Total comp (USD)'
                            ? '80px'
                            : column === 'BU Head Comments'
                            ? '200px'
                            : column === 'Review Committee Comments'
                            ? '220px'
                            : column === 'Final BU Head Approval'
                            ? '120px'
                            : column === 'Initial BU Head Approval'
                            ? '120px'
                            : column === 'Change Accepted by Review Committee'
                            ? '120px'
                            : 'auto',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.2',
                        maxHeight: '2.4rem',
                        position: 'sticky',
                        top: 0,
                        left: frozenColumn.includes(column)
                          ? `${(index + 0.8) * 55}px !important`
                          : 'auto', // Adjust left offset for each frozen column
                        zIndex: frozenColumn.includes(column) ? 25 : 20,
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',

                        transition: 'background 0.3s ease, color 0.3s ease',
                        '&:hover': {
                          background:
                            'linear-gradient(90deg, #FFB547, #F7901D)',
                        },
                      }}
                    >
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        sx={{cursor: 'pointer', gap: 1}}
                      >
                        <span>
                          {column
                            .replace(/_/g, ' ')
                            .split(' ')
                            .slice(0, 10)
                            .join(' ') +
                            (column.split(' ').length > 10 ? '...' : '')}
                        </span>
                      </Box>
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                const isSelected = selectedRows.has(row['Emp ID']);

                return (
                  <TableRow
                    key={row['Emp ID']}
                    sx={{
                      '&:nth-of-type(odd)': {backgroundColor: '#f9f9f9'},
                      '&:hover': {
                        backgroundColor: '#FFD27F',
                        color: 'black',
                        // transition: 'background-color 0.3s ease',
                      },
                      backgroundColor: isSelected ? '#ffd170' : 'inherit',
                      '&.Mui-selected': {
                        backgroundColor: '#f7bf70 !important',
                        color: 'white',
                      },
                    }}
                    selected={isSelected}
                  >
                    <TableCell
                      align='left'
                      sx={{
                        fontSize: '0.75rem',
                        padding: '8px', // Increased padding for better spacing
                        width: '40px',
                        position: 'sticky',
                        left: 0, // Keep it fixed to the left edge
                        zIndex: 5, // Ensure it's on top of other columns
                        backgroundColor: isSelected ? '#ffd170' : 'white',
                      }}
                    >
                      <Checkbox
                        checked={isSelected}
                        onChange={() => handleRowSelect(row['Emp ID'])}
                        size='small'
                        sx={{
                          transform: 'scale(0.8)', // Scale down the checkbox
                          padding: 0.5, // Reduce padding if needed
                          '& .MuiSvgIcon-root': {
                            fontSize: '1rem', // Reduce the icon size further
                          },
                        }}
                      />
                    </TableCell>

                    {columnNames.map(
                      (column, index) =>
                        columnVisibility[column] !== false && (
                          <TableCell
                            key={`${rowIndex}-${column}`}
                            align={
                              [
                                'Current Total Comp',
                                'Promotion (Yes/ No)',
                                'Proposed Total Comp',
                                'Proposed Total Base',
                                'Proposed Total Bonus',
                                'Proposed Total Cash',
                                'Proposed Total ESOP',
                                'Proposed Blend',
                                'Retention ESOP',
                                'Department',
                                '% Increase in Comp',
                                'Final Increment %',
                                'Final Increment Amount',
                                'Final Total Comp',
                                'Final Total Base',
                              ].includes(column)
                                ? 'center'
                                : 'left'
                            }
                            sx={{
                              fontSize: '0.90rem',
                              fontFamily: '"Nanum Myeongjo", serif',
                              padding: '8px', // Increased padding for better spacing
                              width:
                                column === 'Final Increment %' ||
                                column === 'Final Increment Amount' ||
                                column === 'Final Total Comp'
                                  ? '40px'
                                  : column === 'BU Head Comments' ||
                                    column === 'Review Committee Comments'
                                  ? '150px'
                                  : 'auto',
                              whiteSpace: 'normal',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              position: frozenColumn.includes(column)
                                ? 'sticky'
                                : 'static',
                              left: frozenColumn.includes(column)
                                ? `${(index + 0.8) * 55}px`
                                : 'auto',
                              zIndex: frozenColumn.includes(column)
                                ? 5
                                : 'auto',
                              backgroundColor: isSelected
                                ? '#ffd170'
                                : frozenColumn.includes(column)
                                ? 'white'
                                : 'inherit',
                              '&.Mui-selected': {
                                backgroundColor: '#f7bf80 !important',
                                color: 'white',
                              },

                              // transition: 'background-color 0.3s ease', // Added transition for smooth background color change
                            }}
                          >
                            {column === 'Display Name' ? (
                              <Box
                                onClick={() => handleDisplayNameClick(row)}
                                sx={{
                                  cursor: 'pointer',
                                  width: '150px', // Adjust the width as needed
                                  maxWidth: '100%', // Ensure it doesn't exceed the container width
                                  color: 'black',
                                  fontWeight: 'bold',
                                  whiteSpace: 'normal', // Allow text wrapping
                                  overflowWrap: 'break-word', // Handle word breaking for long strings
                                  wordBreak: 'break-word', // Fallback for additional word break support
                                }}
                              >
                                {row[column] || '-'}
                              </Box>
                            ) : column === 'Initial BU Head Approval' ? (
                              <select
                                value={
                                  VpInitialValues[row['Emp ID']] ||
                                  row['Initial BU Head Approval'] ||
                                  ''
                                }
                                disabled={
                                  IncrementStatus[rowIndex] === 'Completed' ||
                                  row['Initial Increment Status'] ===
                                    'Completed' ||
                                  PMS_ROLE === 'hr' ||
                                  PMS_ROLE === 'review_committee'
                                }
                                onChange={(e) =>
                                  handleVpInitialValueChange(
                                    row['Emp ID'],
                                    e.target.value
                                  )
                                }
                                style={{
                                  width: '100%',
                                  maxWidth: '180px',
                                  padding: '6px 12px',
                                  borderRadius: '6px',
                                  fontSize: '0.85rem',
                                  fontWeight: '500',
                                  fontFamily: '"Nanum Myeongjo", serif',
                                  backgroundColor:
                                    row['Initial Increment Status'] ===
                                    'Completed'
                                      ? '#f5f5f5'
                                      : '#fff',
                                  border: '1px solid #ccc',
                                  color:
                                    row['Initial Increment Status'] ===
                                    'Completed'
                                      ? '#888'
                                      : '#333',
                                  appearance: 'none',
                                  textAlign: 'center',
                                  cursor:
                                    row['Initial Increment Status'] ===
                                    'Completed'
                                      ? 'not-allowed'
                                      : 'pointer',
                                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)',
                                  transition:
                                    'border-color 0.3s ease, box-shadow 0.3s ease',
                                  outline: 'none',
                                  boxSizing: 'border-box',
                                  pointerEvents:
                                    IncrementStatus[rowIndex] === 'Completed' ||
                                    PMS_ROLE === 'hr' ||
                                    PMS_ROLE === 'review_committee'
                                      ? 'none'
                                      : 'auto', // Prevent pointer events when disabled
                                }}
                                aria-label='Dropdown menu for VP approval selection'
                                onFocus={(e) =>
                                  (e.target.style.borderColor = '#007bff')
                                }
                                onBlur={(e) =>
                                  (e.target.style.borderColor = '#ccc')
                                }
                              >
                                {' '}
                                <option value='' disabled>
                                  Select
                                </option>{' '}
                                <option value='Approved'>Approved</option>
                                <option value='Proposed'>Proposed</option>
                              </select>
                            ) : column === 'BU Head Comments' ? (
                              <Tooltip
                                title={
                                  hoveredRow === rowIndex
                                    ? VpComments[row['Emp ID']] ||
                                      row['BU Head Comments'] ||
                                      'No comments'
                                    : ''
                                }
                                arrow
                                placement='top'
                              >
                                <span
                                  style={{width: '100%'}}
                                  onMouseEnter={() => setHoveredRow(rowIndex)}
                                  onMouseLeave={() => setHoveredRow(null)}
                                >
                                  <InputBase
                                    size='small'
                                    value={
                                      VpComments[row['Emp ID']] !== undefined
                                        ? VpComments[row['Emp ID']]
                                        : row['BU Head Comments']
                                    }
                                    onChange={(e) =>
                                      handleVpChange(
                                        row['Emp ID'],
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      IncrementStatus[row['Emp ID']] ===
                                        'Completed' ||
                                      PMS_ROLE === 'hr' ||
                                      PMS_ROLE === 'review_committee'
                                    }
                                    placeholder='Enter comments'
                                    sx={{
                                      width: '100%',
                                      padding: '6px 10px',
                                      borderRadius: '10px',
                                      fontSize: '0.9rem',
                                      fontFamily: '"Nanum Myeongjo", serif',
                                      backgroundColor:
                                        row['Initial Increment Status'] ===
                                        'Completed'
                                          ? '#f0f0f0'
                                          : '#ffffff',
                                      border: '1px solid',
                                      borderColor:
                                        row['Initial Increment Status'] ===
                                        'Completed'
                                          ? '#ddd'
                                          : '#ccc',
                                      transition: 'all 0.3s ease-in-out',
                                      color:
                                        row['Initial Increment Status'] ===
                                        'Completed'
                                          ? '#777'
                                          : '#333',
                                      cursor:
                                        row['Initial Increment Status'] ===
                                        'Completed'
                                          ? 'not-allowed'
                                          : 'text',
                                      pointerEvents:
                                        row['Initial Increment Status'] ===
                                        'Completed'
                                          ? 'none'
                                          : 'auto',
                                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                      '&:hover': {
                                        borderColor:
                                          row['Initial Increment Status'] !==
                                          'Completed'
                                            ? '#888'
                                            : '#ddd',
                                      },
                                      '&:focus': {
                                        borderColor: '#007BFF',
                                        backgroundColor: '#fff',
                                        boxShadow:
                                          '0 0 8px rgba(0, 123, 255, 0.5)',
                                        outline: 'none',
                                      },
                                      '&::placeholder': {
                                        color:
                                          row['Initial Increment Status'] ===
                                          'Completed'
                                            ? '#bbb'
                                            : '#999',
                                        fontWeight: '400',
                                      },
                                    }}
                                    onFocus={() => setHoveredRow(row['Emp ID'])}
                                    onBlur={() => setHoveredRow(null)}
                                  />
                                </span>
                              </Tooltip>
                            ) : column ===
                              'Change Accepted by Review Committee' ? (
                              <select
                                value={
                                  HRValue[row['Emp ID']] ||
                                  row['Change Accepted by Review Committee'] ||
                                  ''
                                }
                                disabled={
                                  PMS_ROLE === 'vp' ||
                                  PMS_ROLE === 'vp+' ||
                                  PMS_ROLE === 'vp-'
                                }
                                onChange={(e) =>
                                  handleHRValueChange(
                                    row['Emp ID'],
                                    e.target.value
                                  )
                                }
                                style={{
                                  width: '100%',
                                  maxWidth: '180px',
                                  padding: '6px 12px',
                                  borderRadius: '6px',
                                  fontSize: '0.9rem',
                                  fontWeight: '500',
                                  backgroundColor: '#fff',
                                  border: '1px solid #ccc',
                                  fontFamily: '"Nanum Myeongjo", serif',
                                  color: '#333',
                                  appearance: 'none',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)',
                                  transition:
                                    'border-color 0.3s ease, box-shadow 0.3s ease',
                                  outline: 'none',
                                  boxSizing: 'border-box',
                                }}
                                aria-label='Dropdown menu for status selection'
                                onFocus={(e) =>
                                  (e.target.style.borderColor = '#007bff')
                                }
                                onBlur={(e) =>
                                  (e.target.style.borderColor = '#ccc')
                                }
                              >
                                {' '}
                                <option value='' disabled>
                                  Select
                                </option>{' '}
                                <option value='Not Applicable'>
                                  Not Applicable
                                </option>
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            ) : column === 'Final Increment %' ? (
                              <InputBase
                                size='small'
                                //   value={VpInitialValues[row['Emp ID']] || ''}
                                // onChange={(e) => handleVpInitialValueChange(row['Emp ID'], e.target.value)}
                                value={
                                  incrementper[row['Emp ID']] !== undefined
                                    ? incrementper[row['Emp ID']]
                                    : row['Final Increment %']
                                }
                                onChange={(e) =>
                                  handleincrementper(
                                    row['Emp ID'],
                                    e.target.value
                                  )
                                }
                                placeholder='Enter Increment %'
                                disabled={
                                  PMS_ROLE === 'vp' ||
                                  PMS_ROLE === 'vp+' ||
                                  PMS_ROLE === 'vp-'
                                }
                                sx={{
                                  width: '100%',
                                  padding: '4px 8px',
                                  borderRadius: '8px',
                                  fontFamily: '"Nanum Myeongjo", serif',
                                  fontSize: '0.9rem',
                                  backgroundColor: '#f9f9f9',
                                  border: '1px solid #ccc',
                                  transition: 'all 0.3s ease',
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                inputProps={{
                                  style: {textAlign: 'center'},
                                }}
                              />
                            ) : column === 'Final Increment Amount' ? (
                              <InputBase
                                size='small'
                                value={
                                  increment[row['Emp ID']]
                                    ? increment[row['Emp ID']]
                                    : row['Final Increment Amount']
                                }
                                onChange={(e) =>
                                  handleincrement(row['Emp ID'], e.target.value)
                                }
                                placeholder='Enter Increment in Amount'
                                disabled={
                                  PMS_ROLE === 'vp' ||
                                  PMS_ROLE === 'vp+' ||
                                  PMS_ROLE === 'vp-'
                                }
                                sx={{
                                  width: '100%',
                                  padding: '4px 8px',
                                  borderRadius: '8px',
                                  fontFamily: '"Nanum Myeongjo", serif',
                                  fontSize: '0.9rem',
                                  backgroundColor: '#f9f9f9',
                                  border: '1px solid #ccc',
                                  transition: 'all 0.3s ease',
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                inputProps={{
                                  style: {textAlign: 'center'},
                                }}
                              />
                            ) : column === 'Review Committee Comments' ? (
                              <Tooltip
                                title={
                                  hoveredRow === row['Emp ID']
                                    ? HrComments[row['Emp ID']] ||
                                      row['Review Committee Comments'] ||
                                      'No comments'
                                    : ''
                                }
                                arrow
                                placement='top'
                              >
                                <span
                                  style={{width: '100%'}}
                                  onMouseEnter={() => setHoveredRow(rowIndex)}
                                  onMouseLeave={() => setHoveredRow(null)}
                                >
                                  {/* //value={VpInitialValues[row['Emp ID']] || ''}
                                  // onChange=
                                  {(e) =>
                                    handleVpInitialValueChange(
                                      row['Emp ID'],
                                      e.target.value
                                    )
                                  } */}
                                  <InputBase
                                    size='small'
                                    value={
                                      HrComments[row['Emp ID']] !== undefined
                                        ? HrComments[row['Emp ID']]
                                        : row['Review Committee Comments']
                                    }
                                    onChange={(e) =>
                                      handlehrcomments(
                                        row['Emp ID'],
                                        e.target.value
                                      )
                                    }
                                    placeholder='Enter comments'
                                    disabled={
                                      PMS_ROLE === 'vp' ||
                                      PMS_ROLE === 'vp+' ||
                                      PMS_ROLE === 'vp-'
                                    }
                                    sx={{
                                      width: '100%',
                                      padding: '6px 10px',
                                      borderRadius: '10px',
                                      fontSize: '0.9rem',
                                      backgroundColor: '#ffffff',
                                      border: '1px solid #ccc',
                                      fontFamily: '"Nanum Myeongjo", serif',
                                      transition: 'all 0.3s ease-in-out',
                                      color: '#333',
                                      cursor:
                                        PMS_ROLE === 'vp' ||
                                        PMS_ROLE === 'vp+' ||
                                        PMS_ROLE === 'vp-'
                                          ? 'not-allowed'
                                          : 'text',
                                      pointerEvents:
                                        PMS_ROLE === 'vp' ||
                                        PMS_ROLE === 'vp+' ||
                                        PMS_ROLE === 'vp-'
                                          ? 'none'
                                          : 'auto',
                                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                      '&:hover': {
                                        borderColor: '#888',
                                      },
                                      '&:focus': {
                                        borderColor: '#007BFF',
                                        backgroundColor: '#fff',
                                        boxShadow:
                                          '0 0 8px rgba(0, 123, 255, 0.5)',
                                        outline: 'none',
                                      },
                                      '&::placeholder': {
                                        color: '#999',
                                        fontWeight: '400',
                                      },
                                    }}
                                    onFocus={() => setHoveredRow(rowIndex)}
                                    onBlur={() => setHoveredRow(null)}
                                  />
                                </span>
                              </Tooltip>
                            ) : column === 'Final BU Head Approval' ? (
                              <select
                                value={
                                  FinalVpAprroval[row['Emp ID']] ||
                                  row['Final BU Head Approval'] ||
                                  ''
                                }
                                onChange={(e) =>
                                  handleFinalVpAprroval(
                                    row['Emp ID'],
                                    e.target.value
                                  )
                                }
                                disabled={
                                  row['Final Increment Status'] ===
                                    'Completed' ||
                                  PMS_ROLE === 'hr' ||
                                  PMS_ROLE === 'review_committee'
                                }
                                style={{
                                  width: '100%',
                                  maxWidth: '180px',
                                  padding: '6px 12px',
                                  borderRadius: '6px',
                                  fontSize: '0.9rem',
                                  fontWeight: '500',
                                  backgroundColor: '#fff',
                                  fontFamily: '"Nanum Myeongjo", serif',
                                  border: '1px solid #ccc',
                                  color: '#333',
                                  appearance: 'none',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.08)',
                                  transition:
                                    'border-color 0.3s ease, box-shadow 0.3s ease',
                                  outline: 'none',
                                  boxSizing: 'border-box',
                                }}
                                aria-label='Dropdown menu for status selection'
                                onFocus={(e) =>
                                  (e.target.style.borderColor = '#007bff')
                                }
                                onBlur={(e) =>
                                  (e.target.style.borderColor = '#ccc')
                                }
                              >
                                <option value='' disabled>
                                  Select
                                </option>{' '}
                                {/* Placeholder with disabled */}
                                <option value='Accepted'>Accepted</option>
                                <option value='Declined'>Declined</option>
                              </select>
                            ) : row[column] !== null ? (
                              typeof row[column] === 'number' ? (
                                column === '% Increase in Comp' ||
                                column === 'Final Increment %' ? (
                                  row[column].toFixed(2) // Show 2 decimal places for specific columns
                                ) : (
                                  Math.floor(row[column]).toLocaleString() // Format number with commas
                                )
                              ) : (
                                row[column].toString()
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          position: 'fixed',
          bottom: '-2px',
          left: 0,
          width: 'auto',
          margin: 0,
          padding: 0,
          zIndex: -10,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]} // Add rows per page options here
          component='div'
          count={filteredData.length}
          rowsPerPage={rowsPerPage} // Use a state variable to manage the selected rows per page
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <PersonDetailModal
        open={modalOpen}
        onClose={handleCloseModal}
        person={selectedPerson}
      />
    </Box>
  );
};

export default TableComponent;
