import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {useSelector} from 'react-redux';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import Glossary from './components/Glossary/Glossary';
import PrivateRoutes from './components/Utilities/PrivateRoutes';
import AdminNew from './pages/Admin/AdminNew';
import FileList from './pages/FileDownload/FileList.js';
import Home from './pages/HomeVersionTwo/HomePageVersionTwo';
import IncrementMatrix from './pages/IncrementMatrix/incrementMatrix';
import Login from './pages/Login/Login';
import HomePage from './pages/PMS Page/pmsHomePage';
import PmsFileUpload from './pages/PMSFileUpload/PmsFileUpload';
import ProjectionsRemarks from './pages/Remarks/ProjectionsRemarks';
import ReportPage from './pages/Reports/ReportPage';
import TwoWayHome from './pages/TwoWayFormResponse/TwoWayFormResponse';
import UserProfile from './pages/UserProfile/UserProfile';
function App() {
  let user = useSelector((state) => state.PowerBi.user);
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '9d5e64f0-ccad-44d2-a134-083ba92226aa',
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path='reports' element={<Home />} />
            {/* <Route
							path='reports/v2'
							element={<Home />}
						/> */}
            {user?.ISADMIN && (
              <Route path='/admin'>
                <Route path='' element={<AdminNew />} />
                <Route path='report' element={<ReportPage />} />
              </Route>
            )}
            <Route path='/glossary' element={<Glossary />} />
            <Route path='form/:uuid/:id' element={<TwoWayHome />} />
            <Route path='/remarks' element={<ProjectionsRemarks />} />

            <Route
              path='/calibration-dashboard'
              element={
                ['cp', 'vp', 'hr', 'review_committee', 'vp+', 'vp-'].includes(
                  user?.PMS_ACCESS
                ) ? (
                  <UserProfile />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path='/upload'
              element={
                ['hr', 'review_committee'].includes(user?.PMS_ACCESS) ? (
                  <PmsFileUpload />
                ) : (
                  <Home />
                )
              }
            />
            {/* Route for download */}
            <Route
              path='/download'
              element={
                ['hr', 'review_committee'].includes(user?.PMS_ACCESS) ? (
                  <FileList />
                ) : (
                  <Home />
                )
              }
            />

            <Route
              path='/increment-matrix'
              element={
                ['cp', 'vp', 'hr', 'review_committee', 'vp+', 'vp-'].includes(
                  user?.PMS_ACCESS
                ) ? (
                  <IncrementMatrix />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path='/pms-page'
              element={
                ['cp', 'vp', 'hr', 'review_committee', 'vp+', 'vp-'].includes(
                  user?.PMS_ACCESS
                ) ? (
                  <HomePage />
                ) : (
                  <Home />
                )
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
