import axios from 'axios';
import config from '../../config';

const getReports = async ({googleIdToken}) => {
	try {
		const result = await axios.get(`${config.apiURL}/getallReports`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const getGroupNames = async ({googleIdToken}) => {
	try {
		const result = await axios.get(`${config.apiURL}/getGroups`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (e) {
		return [];
	}
};

const AddorEditReportsApi = async (formData, reportId, googleIdToken) => {
	try {
		const res = await axios.post(`${config.apiURL}/addUpdateReport`, {
			Authorization: `Bearer ${googleIdToken}`,
			formData,
			reportId,
		});
		return res;
	} catch (err) {
		return err;
	}
};

const deleteReport = async (report, googleIdToken) => {
	try {
		const res = await axios.delete(`${config.apiURL}/deleteReportById`, {
			data: {report, Authorization: `Bearer ${googleIdToken}`},
		});
		return res;
	} catch (err) {
		return err;
	}
};

const getUsersByReportApi = async (reportId, googleIdToken) => {
	try {
		const result = await axios.get(`${config.apiURL}/v2/getReportUserMapping`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
				reportid: reportId,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (e) {
		return [];
	}
};

export {
	getReports,
	getGroupNames,
	deleteReport,
	AddorEditReportsApi,
	getUsersByReportApi,
};
