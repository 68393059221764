import {Download, Logout, UploadFile} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {Box, Button, IconButton, InputBase} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {default as React} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Logo from '../../components/Userprofile/logoImage';

const AppBarComponent = ({
  searchText,
  setSearchText,
  handleDownloadExcel,
  columnFilters,
  handleClearFilter,
  handleClearAllFilters,
  handleDialogOpen,
  selectedVP,
  selectedBudget,
  handleGoBack,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.PowerBi.user);
  const PMS_ROLE = user?.PMS_ACCESS;

  return (
    <>
      <Logo />
      <Box
        sx={{
          position: 'absolute',
          top: 16, // Adjust this to set the distance from the top  page
          right: 16, // Adjust this to set the distance from the right side of the page
          display: 'flex',
          alignItems: 'center', // Align items horizontal
          gap: 2, // Add space between the components
        }}
      >
        {/* Search Box */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
            padding: '0 8px',
            width: '240px',
            border: '1px solid #ccc',
            boxShadow: 1,
          }}
        >
          <SearchIcon sx={{color: '#777'}} />
          <InputBase
            placeholder='Search…'
            inputProps={{'aria-label': 'search'}}
            sx={{
              marginLeft: 1,
              flex: 1,
              fontFamily: '"Nanum Myeongjo", serif',
              color: '#333',
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Box>

        {/* Button */}
        <Button
          variant='outlined'
          onClick={handleDialogOpen}
          sx={{
            padding: '4px 10px',
            fontSize: '0.7rem',
            minWidth: 'auto',
            borderColor: '#F7901D',
            borderWidth: '2px',
            color: '#F7901D',
            backgroundColor: 'white',
            '&:hover': {
              borderColor: '#F7901D',
              backgroundColor: '#F7901D',
              color: 'white',
            },
            textTransform: 'none',
          }}
        >
          Customize Columns
        </Button>

        {/* Container for Go Back, Upload, and Download buttons */}
        <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
          {/* Go Back Button */}

          {/* Upload Icon */}
          {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') && (
            <IconButton
              color='inherit'
              sx={{
                p: 1,
                color: 'black',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)',
                  transform: 'scale(1.1)',
                },
              }}
              onClick={() => navigate('/upload')}
            >
              <UploadFile />
            </IconButton>
          )}

          {/* Download Icon */}
          {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') && (
            <IconButton
              color='inherit'
              sx={{
                p: 1,
                color: 'black',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)',
                  transform: 'scale(1.1)',
                },
              }}
              onClick={() => navigate('/download')}
            >
              <Download />
            </IconButton>
          )}

          {(PMS_ROLE === 'vp' || PMS_ROLE === 'vp+' || PMS_ROLE === 'vp-') && (
            <Tooltip title='Only the currently filtered data will be downloaded'>
              <IconButton
                color='inherit'
                sx={{
                  p: 1,
                  color: 'black',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)',
                    transform: 'scale(1.1)',
                  },
                }}
                onClick={handleDownloadExcel}
              >
                <Download />
              </IconButton>
            </Tooltip>
          )}

          <IconButton
            color='inherit'
            sx={{
              p: 1,
              color: 'black',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)',
                transform: 'scale(1.1)',
              },
            }}
            onClick={handleGoBack}
          >
            <Logout />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default AppBarComponent;
