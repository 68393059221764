import {Close} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchCalibrationStatus,
  fetchCpNames,
  fetchPeopleUnderVP,
  fetchTaggingOptions,
  fetchVpNames,
  resetState,
  setSelectedCalibrationStatus,
  setSelectedCP,
  setSelectedPerson,
  setSelectedTagging,
  setSelectedVP,
  updateCalibrationData,
} from '../../redux/userSlice';

const VPWiseSelectionCard = () => {
  const dispatch = useDispatch();
  const {
    vpNames,
    taggingOptions,
    cpNames,
    peopleUnderVP,
    selectedVP,
    selectedTagging,
    selectedCP,
    selectedPerson,
    calibrationStatuses,
    selectedCalibrationStatus,
  } = useSelector((state) => state.user);
  const user = useSelector((state) => state.PowerBi.user);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [finalReviewVP, setFinalReviewVP] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for tracking async operation

  // const [isReviewVPVisible, setReviewVPVisible] = useState(false);
  const [calibrationStatus, setCalibrationStatus] = useState('Not Started');
  const PMS_ROLE = user?.PMS_ACCESS;
  const vplist = user?.Select_VP_List;

  // Local loading states
  const [loadingVp, setloadingVp] = useState(false);
  const [loadingCp, setLoadingCp] = useState(false);
  const [loadingTagging, setLoadingTagging] = useState(false);
  const [loadingPeople, setLoadingPeople] = useState(false);
  const [loadingCalibration, setLoadingCalibration] = useState(false);
  const [selectedReviewVP, setSelectedReviewVP] = useState('');

  // Load VP names
  const vpArray = useMemo(() => {
    return (
      vplist?.split(',').map((name) => ({'Final VP tagging': name.trim()})) ||
      [] // Create an array of objects
    );
  }, [vplist]); // vplist is the dependency

  // Load VP names based on PMS_ROLE
  useEffect(() => {
    if (PMS_ROLE === 'vp-') {
      // Update vpNames with the extracted vpArray
      dispatch(fetchVpNames(vpArray));
    } else {
      // Fetch VP names normally
      setloadingVp(true);
      dispatch(fetchVpNames());
      setloadingVp(false);
    }
  }, [PMS_ROLE, dispatch, vpArray]);

  // Fetch tagging options after VP names have been fetched
  useEffect(() => {
    if (selectedVP) {
      setLoadingTagging(true);
      dispatch(fetchTaggingOptions(selectedVP)).finally(() => {
        setLoadingTagging(false);
      });
    }
  }, [selectedVP, dispatch]); // Add PMS_ROLE as a dependency to track its changes
  const displayedVpNames = PMS_ROLE === 'vp-' ? vpArray : vpNames;

  // Fetch CP names when VP and tagging are selected
  useEffect(() => {
    if (selectedVP && selectedTagging) {
      setLoadingCp(true);
      dispatch(
        fetchCpNames({vpName: selectedVP, tagging: selectedTagging})
      ).finally(() => {
        setLoadingCp(false);
      });
    }
  }, [selectedVP, selectedTagging, dispatch]);

  // Fetch people under VP when CP and Calibration Status are selected
  useEffect(() => {
    if (
      selectedVP &&
      selectedTagging &&
      selectedCP &&
      selectedCalibrationStatus
    ) {
      setLoadingPeople(true);
      dispatch(
        fetchPeopleUnderVP({
          vpName: selectedVP,
          tagging: selectedTagging,
          cpName: selectedCP,
          calibrationStatus: selectedCalibrationStatus.join(','), // Join array if multiple values selected
        })
      ).finally(() => {
        setLoadingPeople(false);
      });
    }
  }, [
    selectedVP,
    selectedTagging,
    selectedCP,
    selectedCalibrationStatus,
    dispatch,
  ]);

  useEffect(() => {
    if (PMS_ROLE === 'vp') {
      const fullName = `${user?.FIRSTNAME} ${
        user?.MIDDLENAME ? user.MIDDLENAME + ' ' : ''
      }${user?.LASTNAME}`.trim();
      dispatch(setSelectedVP(fullName));
    }
  }, [dispatch, PMS_ROLE, user]);

  // Fetch Calibration Status when VP is selected

  useEffect(() => {
    if (selectedVP) {
      setLoadingCalibration(true);
      dispatch(fetchCalibrationStatus()).finally(() => {
        setLoadingCalibration(false);
      });
    }
  }, [selectedVP, dispatch]);
  useEffect(() => {
    if (selectedPerson) {
      setCalibrationStatus(
        selectedPerson['Calibration Status'] || 'Not Started'
      );
    }
  }, [selectedPerson]);

  const handleVPSelect = (vpName) => {
    dispatch(setSelectedVP(vpName));
    // Reset other selections when VP changes
    dispatch(setSelectedTagging(''));
    dispatch(setSelectedCP(''));
    dispatch(setSelectedCalibrationStatus(''));
    dispatch(setSelectedPerson(null));
    // setReviewVPVisible(false);
  };
  const resetModalStatenew = () => {
    // Only reset states other than selectedVP
    dispatch(setSelectedTagging(''));
    dispatch(setSelectedCP(''));
    dispatch(setSelectedCalibrationStatus(''));
    dispatch(setSelectedPerson(null));
  };

  const handleTaggingSelect = (tagging) => {
    dispatch(setSelectedTagging(tagging));
  };

  const handleCPSelect = (cpName) => {
    dispatch(setSelectedCP(cpName));
  };

  const handleCalibrationStatusSelect = (selectedStatuses) => {
    // Prevent unselecting the last calibration status
    if (selectedStatuses.length === 0) {
      // If trying to unselect all, keep the previous selection intact
      return;
    }
    dispatch(setSelectedCalibrationStatus(selectedStatuses)); // Store the array of selected calibration statuses
  };

  const handlePersonSelect = (personName) => {
    const personDetails = peopleUnderVP.find(
      (person) => person['Display Name'] === personName
    );
    dispatch(setSelectedPerson(personDetails));
  };

  const handleSubmit = () => {
    if (selectedPerson) {
      setFinalReviewVP(selectedVP);
      setModalOpen(false);
    }
  };

  const resetModalState = () => {
    dispatch(resetState());

    setFinalReviewVP('');
  };
  useEffect(() => {
    if (peopleUnderVP && selectedPerson) {
      const updatedPerson = peopleUnderVP.find(
        (person) => person['Display Name'] === selectedPerson['Display Name']
      );
      if (updatedPerson) {
        dispatch(setSelectedPerson(updatedPerson));
      }
    }
  }, [peopleUnderVP, selectedPerson, dispatch]);
  const handleReviewVPSubmit = async () => {
    setLoading(true); // Set loading to true before the async operation starts
    if (selectedPerson && selectedReviewVP) {
      const employeeId = selectedPerson['Emp ID']; // Use the employee ID from selectedPerson
      const data = {'Final VP tagging': selectedReviewVP}; // Create data object to send

      try {
        // First, update the calibration data
        await dispatch(updateCalibrationData({employeeId, data})); // Wait for the update to complete

        // Refetch the list to apply the updated filter and ensure the current employee's status is updated
        await dispatch(
          fetchPeopleUnderVP({
            vpName: selectedVP,
            tagging: selectedTagging,
            cpName: selectedCP,
            calibrationStatus: selectedCalibrationStatus,
          })
        );

        // Get the index of the current person
        const currentIndex = peopleUnderVP.findIndex(
          (person) => person['Emp ID'] === employeeId
        );

        // Select the next employee if they exist
        const nextPerson = peopleUnderVP[currentIndex + 1];
        if (nextPerson) {
          dispatch(setSelectedPerson(nextPerson)); // Move to the next person
        } else {
          dispatch(setSelectedPerson(null)); // Or handle the end of the list
        }

        // Handle successful update
        setReviewModalOpen(false); // Close the modal
        setFinalReviewVP(selectedReviewVP); // Update the displayed review VP
      } catch (error) {
        console.error('Failed to update Review VP:', error);
      } finally {
        setLoading(false); // Set loading to false once the operation finishes
      }
    } else {
      setLoading(false); // In case no person or VP selected
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        border: '1px solid #ddd',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between', // Use space-between to push items to the edges
        alignItems: 'center',
      }}
    >
      <Typography
        variant='body2'
        sx={{
          cursor: 'pointer',
          color: '#F7901D',
          fontFamily: '"Nanum Myeongjo", serif',
          fontSize: {
            xs: '12px', // small font size for extra-small screens
            sm: '14px', // default font size for small screens
            md: '16px', // medium font size for medium screens
          },
          transition: 'transform 0.2s ease-in-out', // Smooth transition for hover effect
          ':hover': {
            transform: 'scale(1.2)', // Enlarge slightly on hover
          },
        }}
        noWrap // Prevents text from wrapping
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Select BU Head/VP
      </Typography>

      {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') &&
        calibrationStatus !== 'Completed' &&
        selectedPerson && ( // Only render Change VP if selectedPerson exists
          <Typography
            variant='body2'
            sx={{
              cursor: 'pointer',
              color: '#F7901D',
              fontFamily: '"Nanum Myeongjo", serif',
              fontSize: {
                xs: '12px', // small font size for extra-small screens
                sm: '14px', // default font size for small screens
                md: '16px', // medium font size for medium screens
              },
              transition: 'transform 0.2s ease-in-out', // Smooth transition for hover effect
              ':hover': {
                transform: 'scale(1.2)', // Enlarge slightly on hover
              },
            }}
            noWrap // Prevents text from wrapping
            onClick={() => setReviewModalOpen(true)}
          >
            Change BU Head/VP
          </Typography>
        )}

      {/* Modal for VP, Tagging, CP, and Employee selection */}
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography
              variant='h6'
              component='h2'
              sx={{flexGrow: 1, fontSize: '1.0rem'}} // Adjust font size as needed
            >
              <strong>Select BU Head/VP, Tagging, CP, and Employee</strong>
            </Typography>
            {/* {PMS_ROLE !== 'vp' && ( */}
            <IconButton
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <Close />
            </IconButton>
            {/* )} */}

            {/* {PMS_ROLE == 'vp' && (
              <IconButton
                onClick={() => {
                  resetModalStatenew();
                }}
              >
                <Close />
              </IconButton> */}
            {/* )} */}
          </Box>

          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel>Select BU Head/VP</InputLabel>
            <Select
              value={selectedVP || ''}
              onChange={(e) => handleVPSelect(e.target.value)}
              label='Select BU Head/VP'
              disabled={PMS_ROLE === 'vp'}
            >
              {loadingVp ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : displayedVpNames.length > 0 ? (
                displayedVpNames.map((vp, index) => (
                  <MenuItem key={index} value={vp['Final VP tagging']}>
                    {vp['Final VP tagging']}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No VP Names available</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel>Select Tagging</InputLabel>
            <Select
              value={selectedTagging || ''}
              onChange={(e) => handleTaggingSelect(e.target.value)}
              label='Select Tagging'
              disabled={!selectedVP}
            >
              {loadingTagging ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : taggingOptions.length > 0 ? (
                PMS_ROLE === 'vp+' ? (
                  // Check if "DE/MLE/Tech/BI" is available in backend data
                  taggingOptions.some(
                    (tag) => tag['Tagging'] === 'DE/MLE/Tech/BI'
                  ) ? (
                    // If "DE/MLE/Tech/BI" exists in the backend, show that
                    taggingOptions
                      .filter((tag) => tag['Tagging'] === 'DE/MLE/Tech/BI')
                      .map((tag, index) => (
                        <MenuItem key={index} value={tag['Tagging']}>
                          {tag['Tagging']}
                        </MenuItem>
                      ))
                  ) : (
                    // If "DE/MLE/Tech/BI" is not in backend, show a message and prompt for different VP
                    <MenuItem disabled>Please choose a different VP</MenuItem>
                  )
                ) : (
                  // If PMS_ROLE is not 'review_committee', show all tagging options
                  taggingOptions.map((tag, index) => (
                    <MenuItem key={index} value={tag['Tagging']}>
                      {tag['Tagging']}
                    </MenuItem>
                  ))
                )
              ) : (
                <MenuItem disabled>No Tagging Options available</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel>Select CP Name</InputLabel>
            <Select
              value={selectedCP || ''}
              onChange={(e) => handleCPSelect(e.target.value)}
              label='Select CP Name'
              disabled={!selectedTagging}
            >
              {loadingCp ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : cpNames.length > 0 ? (
                cpNames.map((cp, index) => (
                  <MenuItem key={index} value={cp['CP/Manager tagging']}>
                    {cp['CP/Manager tagging']}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No CP Names available</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel>Select Calibration Status</InputLabel>
            <Select
              multiple
              value={selectedCalibrationStatus || []}
              onChange={(e) => handleCalibrationStatusSelect(e.target.value)}
              label='Select Calibration Status'
              disabled={!selectedCP}
              renderValue={(selected) => selected.join(', ')} // Display selected values as a comma-separated string
            >
              {loadingCalibration ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : calibrationStatuses.length > 0 ? (
                calibrationStatuses.map((status, index) => (
                  <MenuItem
                    key={index}
                    value={status['Calibration Status']}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: '#f1f1f1', // Hover effect
                      },
                    }}
                  >
                    <Checkbox
                      checked={selectedCalibrationStatus.includes(
                        status['Calibration Status']
                      )}
                      sx={{
                        color: '#1976d2', // Custom checkbox color
                        '&.Mui-checked': {
                          color: '#1976d2', // Keep the color consistent when checked
                        },
                      }}
                    />
                    <span style={{marginLeft: '8px'}}>
                      {status['Calibration Status']}
                    </span>
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Calibration Status available</MenuItem>
              )}
            </Select>
          </FormControl>

          {selectedCalibrationStatus && (
            <FormControl fullWidth sx={{mb: 2}}>
              <InputLabel>Select Person</InputLabel>
              <Select
                value={selectedPerson ? selectedPerson['Display Name'] : ''}
                onChange={(e) => handlePersonSelect(e.target.value)}
                label='Select Person'
                disabled={!selectedCalibrationStatus}
              >
                {loadingPeople ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : peopleUnderVP.length > 0 ? (
                  peopleUnderVP.map((person, index) => (
                    <MenuItem key={index} value={person['Display Name']}>
                      {person['Display Name']}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No people available</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
              variant='contained'
              onClick={() => handleSubmit()}
              disabled={!selectedPerson}
            >
              Submit
            </Button>
            {PMS_ROLE !== 'vp' && (
              <Button
                variant='text'
                onClick={() => {
                  resetModalState();
                }}
              >
                Reset
              </Button>
            )}
            {PMS_ROLE === 'vp' && (
              <Button
                variant='text'
                onClick={() => {
                  resetModalStatenew();
                }}
              >
                Reset
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Review Modal */}
      {/* Review Modal */}
      <Modal
        open={isReviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        closeAfterTransition
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant='h6' component='h2' sx={{mb: 2}}>
            Select VP for Review
          </Typography>

          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel>Select Review VP</InputLabel>
            <Select
              value={selectedReviewVP}
              onChange={(e) => setSelectedReviewVP(e.target.value)}
              label='Select Review VP'
            >
              {vpNames.map((vp, index) => (
                <MenuItem key={index} value={vp['Final VP tagging']}>
                  {vp['Final VP tagging']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant='contained'
            onClick={handleReviewVPSubmit} // Define this function next
            disabled={!selectedReviewVP || loading} // Disable if no VP is selected
          >
            {loading ? (
              <CircularProgress size={24} /> // Display loading spinner if loading is true
            ) : (
              'Submit Review VP' // Display the text if not loading
            )}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default VPWiseSelectionCard;
