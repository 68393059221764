import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Box, IconButton, Paper, Tooltip, Typography} from '@mui/material';
import {format} from 'date-fns';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentIndex, setSelectedPerson} from '../../redux/userSlice';

const UserProfileSidebar = () => {
  const dispatch = useDispatch();

  // Fetching selected person and people under the VP from Redux
  const selectedPerson = useSelector((state) => state.user.selectedPerson);
  const peopleUnderVP = useSelector((state) => state.user.peopleUnderVP);
  const currentIndex = useSelector((state) => state.user.currentIndex);

  const refs = {
    name: useRef(null),
    empId: useRef(null),
    geo: useRef(null),
    designation: useRef(null),
    department: useRef(null),
    workLocation: useRef(null),
    currentVP: useRef(null),
    currentAccount: useRef(null),
    reviewVP: useRef(null),
    CurrentCp: useRef(null),
    reviewCp: useRef(null),
    joiningDate: useRef(null),
    yearsOfExperience: useRef(null),
    reviewCp: useRef(null),
    yearsOfExperiencetiger: useRef(null),
    twentyfour: useRef(null),
    twentythree: useRef(null),
    twentytwo: useRef(null),
    LastPromotedYear: useRef(null),
    status: useRef(null),
  };

  const [isTruncated, setIsTruncated] = useState({
    name: false,
    empId: false,
    geo: false,
    cptag: false,
    designation: false,
    department: false,
    workLocation: false,
    currentVP: false,
    currentAccount: false,
    reviewVP: false,
    joiningDate: false,
    yearsOfExperience: false,
    reviewCp: false,
    yearsOfExperiencetiger: false,
    twentyfour: false,
    twentythree: false,
    twentytwo: false,
    LastPromotedYear: false,
    status: false,
    reviewdate: false,
  });

  // Effect to update selected person based on currentIndex

  // State to check for text overflow
  useLayoutEffect(() => {
    const checkOverflow = (ref, key) => {
      if (ref.current) {
        setIsTruncated((prev) => ({
          ...prev,
          [key]: ref.current.scrollWidth > ref.current.clientWidth,
        }));
      }
    };

    Object.keys(refs).forEach((key) => {
      checkOverflow(refs[key], key);
    });
  }, [selectedPerson]);
  const currentYear = new Date().getFullYear();
  const currentYearLabel = currentYear - 1; // e.g., 2024
  const previousYearLabel = currentYear - 2; // e.g., 2023
  const yearMinusTwoLabel = currentYear - 3; // e.g., 2022

  const getCurrencySymbol = (geo) => {
    switch (geo) {
      case 'US':
        return '$';
      case 'CN':
        return '$'; // Yuan symbol for China
      case 'UK':
        return ' €'; // Symbol for the UK
      default:
        return '';
    }
  };

  const handleNextPerson = () => {
    if (peopleUnderVP.length > 0) {
      const newIndex = (currentIndex + 1) % peopleUnderVP.length;
      dispatch(setCurrentIndex(newIndex)); // Update current index
      dispatch(setSelectedPerson(peopleUnderVP[newIndex])); // Update selected person
      console.log(`Current index updated to: ${newIndex}`); // Add this line
    }
  };

  const handlePreviousPerson = () => {
    if (peopleUnderVP.length > 0) {
      const newIndex =
        (currentIndex - 1 + peopleUnderVP.length) % peopleUnderVP.length;
      dispatch(setCurrentIndex(newIndex)); // Update current index
      dispatch(setSelectedPerson(peopleUnderVP[newIndex])); // Update selected person
      console.log(`Current index updated to: ${newIndex}`); // Add this line
    }
  };

  const formattedJoiningDate = selectedPerson?.['Joining date']
    ? format(new Date(selectedPerson['Joining date']), 'MMMM d, yyyy')
    : '';
  const formattedReviewDate = selectedPerson?.['Current_Review_Cycle']
    ? format(new Date(selectedPerson['Current_Review_Cycle']), 'MMMM d, yyyy')
    : '';

  return (
    <Paper
      elevation={2}
      sx={{
        padding: 2,
        height: 'auto',
        width: '100%',
        position: 'relative',
        // '&': {
        //   zoom: 0.87, // Sets the zoom level to 90%
        // },
      }}
    >
      <Typography
        variant='h6'
        color='textPrimary'
        align='center'
        sx={{
          mb: 1,
          fontFamily: 'Roboto, sans-serif', // Professional font
          color: '#F7901D',
          fontSize: {xs: '1.1rem', sm: '1.4rem'},
          fontWeight: 500,
          fontFamily: '"Nanum Myeongjo", serif',
        }}
      >
        Employee Details
      </Typography>

      {selectedPerson ? (
        <>
          <Tooltip
            title={isTruncated.name ? selectedPerson['Display Name'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.name}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Name:</strong>
              {selectedPerson['Display Name']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.empId ? selectedPerson['Emp ID'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.empId}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Emp ID:</strong> {selectedPerson['Emp ID']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.joiningDate ? formattedJoiningDate : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.joiningDate}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Joining Date: </strong>
              {formattedJoiningDate}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.geo ? selectedPerson['Geo'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.geo}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Geo:</strong> {selectedPerson['Geo']}
            </Typography>
          </Tooltip>
          <Typography
            variant='body2'
            color='#333333'
            align='left'
            sx={{
              mb: 0.5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '0.8rem',
            }}
          >
            <strong>Currency: </strong>
            {getCurrencySymbol(selectedPerson['Geo'])}
          </Typography>
          <Tooltip
            title={isTruncated.designation ? selectedPerson['Designation'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.designation}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Designation:</strong> {selectedPerson['Designation']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.department ? selectedPerson['Dept'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.department}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Department: </strong>
              {selectedPerson['Dept']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.workLocation ? selectedPerson['Work Location'] : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.workLocation}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Work Location: </strong>
              {selectedPerson['Work Location']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.currentVP ? selectedPerson['Current VP'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.currentVP}
              variant='body2'
              align='left'
              color='#333333'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Current BU Head/VP: </strong>
              {selectedPerson['Current VP']}
            </Typography>
          </Tooltip>

          <Tooltip
            title={
              isTruncated.cptag ? selectedPerson['CP/Manager tagging'] : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.cptag}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Review CP:</strong> {selectedPerson['CP/Manager tagging']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.currentAccount
                ? selectedPerson['Current Account - Nov Data']
                : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.currentAccount}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Current Account: </strong>
              {selectedPerson['Current Account - Nov Data']}
            </Typography>
          </Tooltip>

          <Tooltip
            title={
              isTruncated.reviewVP ? selectedPerson['Final VP tagging'] : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.reviewVP}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Review BU Head/VP: </strong>
              {selectedPerson['Final VP tagging']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.CurrentCp ? selectedPerson['Current Manager'] : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.CurrentCp}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Current Manager: </strong>
              {selectedPerson['Current Manager']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.reviewCp ? selectedPerson['Review Manager'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.reviewCp}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Review Manager: </strong>
              {selectedPerson['Review Manager']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.yearsOfExperience
                ? selectedPerson['Total Experience (years)']
                : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.yearsOfExperience}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Total Experience in Years: </strong>
              {selectedPerson['HR Years of Exp']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.yearsOfExperiencetiger
                ? selectedPerson['Total Experience (Tiger)']
                : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.yearsOfExperiencetiger}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Total Experience (Tiger) in Years: </strong>
              {selectedPerson['HR Years of Exp @ Tiger']}
            </Typography>
          </Tooltip>

          {/* <Tooltip
            title={isTruncated.twentyfour ? selectedPerson['2024'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.twentyfour}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.65rem',
              }}
            >
              <strong>Rating for the year {currentYearLabel}: </strong>
              {selectedPerson['Current_yr_Rating'] === 0
                ? ''
                : selectedPerson['Current_yr_Rating']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.twentythree ? selectedPerson['2023'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.twentythree}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.65rem',
              }}
            >
              <strong>Rating for the year {previousYearLabel}: </strong>
              {selectedPerson['Current-1_yr_Rating'] === 0
                ? ''
                : selectedPerson['Current-1_yr_Rating']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={isTruncated.twentytwo ? selectedPerson['2022'] : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.twentytwo}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.65rem',
              }}
            >
              <strong>Rating for the Year {yearMinusTwoLabel}: </strong>
              {selectedPerson['Current-2_yr_Rating'] === 0
                ? ''
                : selectedPerson['Current-2_yr_Rating']}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.LastPromotedYear
                ? selectedPerson['Last Promoted Year'] === 0
                  ? ''
                  : selectedPerson['Last Promoted Year']
                : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.LastPromotedYear}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.65rem',
              }}
            >
              <strong>Last Promoted Year: </strong>
              {selectedPerson['Last Promoted Year'] === 0
                ? 'Never Promoted'
                : selectedPerson['Last Promoted Year']}
            </Typography>
          </Tooltip> */}

          <Tooltip
            title={isTruncated.reviewdate ? formattedReviewDate : ''}
            arrow
            placement='top'
          >
            <Typography
              ref={refs.reviewdate}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Last Review Date: </strong>
              {formattedReviewDate}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              isTruncated.status ? selectedPerson['Calibration Status'] : ''
            }
            arrow
            placement='top'
          >
            <Typography
              ref={refs.LastPromotedYear}
              variant='body2'
              color='#333333'
              align='left'
              sx={{
                mb: 0.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
              }}
            >
              <strong>Calibration Status: </strong>
              {selectedPerson['Calibration Status']}
            </Typography>
          </Tooltip>
        </>
      ) : (
        <Typography variant='body2' color='textSecondary'>
          No Profile Selected
        </Typography>
      )}
      <Box sx={{position: 'absolute', bottom: 10, right: 0}}>
        <IconButton
          onClick={handlePreviousPerson}
          size='small'
          disabled={peopleUnderVP.length === 0}
        >
          <Tooltip title='Previous Person'>
            <ChevronLeftIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={handleNextPerson}
          size='small'
          disabled={peopleUnderVP.length === 0}
        >
          <Tooltip title='Next Person'>
            <ChevronRightIcon />
          </Tooltip>
        </IconButton>
      </Box>
    </Paper>
  );
};

export default UserProfileSidebar;
