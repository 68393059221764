import {Save, Send} from '@mui/icons-material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
  fetchFilteredIncrementData,
  fetchIncrementData,
  fetchTagFilteredIncrementData,
  updateIncrementData,
} from '../../redux/IncrementSlice'; // Adjust the path as necessary
import AppBarComponent from './AppBarComp';
import FilterComponent from './FilterTab';
import './incrementMatrix.css';
import TableComponent from './IncrementTable';
const IncrementMatrix = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const {incrementData, loading, error} = useSelector(
    (state) => state.increment
  );
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState('');
  const [columnFilters, setColumnFilters] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumn, setActiveColumn] = useState('');
  const [frozenColumn, setFrozenColumn] = useState(['Emp ID', 'Display Name']);
  const [increment, setincrement] = useState({});
  const [incrementper, setincrementper] = useState({});
  const [comp, setcomp] = useState({});
  const [IncrementStatus, setIncrementStatus] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedVP, setSelectedVP] = useState(''); // State to track selected VP
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar open state
  const [selectedRows, setSelectedRows] = useState(new Set()); // Change to store Emp IDs
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar severity
  const [HRValue, setHRValue] = useState([]);
  // const [totalSum, settotalSum] = useState([]);
  const [VpInitialValues, setVpInitialValues] = useState([]);
  const [VpComments, setVpComments] = useState([]);
  const [FinalVpAprroval, setFinalVpArroval] = useState([]);
  const [HrComments, setHrComments] = useState([]);
  const [SelectAlldialogOpen, setSelectAlldialogOpen] = useState(false);
  const [SelectAlldialogOpen2, setSelectAlldialogOpen2] = useState(false);
  const [avgRateIncrementPercentage, setAvgRateIncrementPercentage] =
    useState(0);
  console.log('avgRateIncrementPercentage', avgRateIncrementPercentage);
  const user = useSelector((state) => state.PowerBi.user);
  const PMS_ROLE = user?.PMS_ACCESS;

  const defaultColumns = [
    'Emp ID',
    'Display Name',
    'Geo',
    'Department',
    'Tagging',
    'Designation',
    'Account',

    'Final BU Head Tagging',
    'Review CP',
    'Review Manager',
    'Current Total Comp',
    'Promotion (Yes/ No)',
    'Proposed Total Comp',
    'Proposed Total Base',
    'Proposed Total Bonus',
    'Proposed Total Cash',
    'Proposed Total ESOP',
    'Proposed Blend',
    'Retention ESOP',
    '% Increase in Comp',
    'Increase in Comp Amount',
    'Initial BU Head Approval',
    'BU Head Comments',
    'Change Accepted by Review Committee',
    'Final Increment %',
    'Final Increment Amount',
    'Final Total Comp',

    'Review Committee Comments',
    'Final BU Head Approval',
  ];
  const vplist = user?.Select_VP_List;

  // Use useMemo to memoize the vpArray computation
  const vpArray = useMemo(() => {
    return vplist?.split(',').map((name) => name.trim()) || [];
  }, [vplist]);

  const fullName = `${user?.FIRSTNAME} ${
    user?.MIDDLENAME ? user.MIDDLENAME + ' ' : ''
  }${user?.LASTNAME}`.trim();

  useEffect(() => {
    // Automatically apply the VP filter when the PMS_ROLE is 'vp'
    if (PMS_ROLE === 'vp') {
      // Automatically set the selectedVP to fullName for VP roles
      setSelectedVP(fullName); // Set the selected VP to the full name
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        'Final BU Head Tagging': fullName, // Automatically apply the filter to 'Final BU Head Tagging' using fullName
      }));

      // Fetch the filtered data for the 'vp' role using fullName
      dispatch(fetchFilteredIncrementData([fullName]));
    } else if (PMS_ROLE === 'vp-') {
      dispatch(fetchFilteredIncrementData(vpArray));
    } else if (PMS_ROLE === 'vp+') {
      dispatch(fetchTagFilteredIncrementData());
    } else {
      dispatch(fetchIncrementData());
    }
  }, [PMS_ROLE, fullName, vpArray, dispatch]); // Watch for changes in PMS_ROLE and fullName
  useEffect(() => {
    if (selectedVP) {
      // If a VP is selected, ensure that the correct BU Budget and data are passed
      const selectedRow = incrementData.find(
        (row) => row['Final BU Head Tagging'] === selectedVP
      );
      setSelectedBudget(selectedRow?.['BU Head Budget'] || null); // Set the selected BU Budget or null if not found
    }
  }, [selectedVP, incrementData]); // Watch for changes in selectedVP or incrementData

  const columnNames = defaultColumns.filter(
    (column) =>
      incrementData[0] && Object.keys(incrementData[0]).includes(column)
  );

  useEffect(() => {}, [dispatch]);
  const filteredData = incrementData.filter((row) => {
    const matchesSearch = columnNames.some((column) =>
      row[column]?.toString().toLowerCase().includes(searchText.toLowerCase())
    );
    const matchesFilters = Object.entries(columnFilters).every(
      ([column, value]) => value === 'All' || row[column] === value
    );

    // Filter the table data based on column filters and global search
    return matchesSearch && matchesFilters;
  });
  const calculateTotalCompIncr = () => {
    // Ensure filteredData and selectedVP are defined and valid
    if (!incrementData || !selectedVP) return 0;

    // Filter rows only by 'Final BU Head Tagging'
    const vpFilteredData = incrementData.filter(
      (row) => row['Final BU Head Tagging'] === selectedVP
    );
    // Calculate the total sum
    return vpFilteredData.reduce((sum, row) => {
      const incrementAmount =
        row['Final Increment Amount'] && row['Final Increment Amount'] !== '0'
          ? parseFloat(row['Final Increment Amount'])
          : parseFloat(row['Increase in Comp Amount']);

      return sum + (isNaN(incrementAmount) ? 0 : incrementAmount);
    }, 0);
  };

  // Inside the IncrementMatrix component:
  const totalSum = calculateTotalCompIncr(); // Calculate the total sum
  console.log(totalSum);

  useEffect(() => {
    calculateTotalCompIncr(); // Recalculate whenever VP changes
  }, [selectedVP, incrementData]);
  const calculateAvgRateIncrementPercentage = () => {
    if (!incrementData || !selectedVP) return 0;

    // Filter data for the selected VP
    const vpFilteredData = incrementData.filter(
      (row) => row['Final BU Head Tagging'] === selectedVP
    );

    // Calculate the total sum of 'Current Total Comp' for the selected VP
    const totalCurrentComp = vpFilteredData.reduce((sum, row) => {
      return sum + (parseFloat(row['Current Total Comp']) || 0);
    }, 0);
    console.log('hi', totalCurrentComp);
    if (totalCurrentComp === 0) return 0; // Avoid division by zero

    // Calculate the average increment percentage
    return ((totalSum / totalCurrentComp) * 100).toFixed(2);
  };

  useEffect(() => {
    const newPercentage = calculateAvgRateIncrementPercentage();
    console.log(newPercentage);

    setAvgRateIncrementPercentage(newPercentage);
  }, [selectedVP, incrementData]); // Recalculate when VP or data changes

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        flexDirection='column'
      >
        <CircularProgress size={50} color='primary' />
        <Typography variant='h6' mt={2} color='textSecondary'>
          Loading, please wait...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign='center' mt={4}>
        <Typography variant='h6' color='error'>
          Oops! Something went wrong. Please try again later.
        </Typography>
      </Box>
    );
  }

  if (incrementData.length === 0) {
    return (
      <Box textAlign='center' mt={4}>
        <Typography variant='h6' color='textSecondary'>
          No data available to display. Please check back later.
        </Typography>
      </Box>
    );
  }

  // Fetch unique values for a column
  const getUniqueValues = (column) => {
    return Array.from(new Set(incrementData.map((row) => row[column] ?? '-')));
  };
  const handleDialogClose1 = () => {
    setSelectAlldialogOpen(false); // Close the dialog
  };
  const handleDownloadExcel = () => {
    if (!filteredData || filteredData.length === 0) {
      alert('No data available to export.');
      return;
    }

    // Ensure only defaultColumns are included in the exported data
    const filteredExportData = filteredData.map((row) => {
      let filteredRow = {};
      defaultColumns.forEach((col) => {
        filteredRow[col] = row[col] || ''; // Ensures undefined values are handled gracefully
      });
      return filteredRow;
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredExportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Increment Data');

    // Generate the Excel file and trigger the download
    XLSX.writeFile(workbook, 'IncrementData.xlsx');
  };

  const handleDialogOpen1 = () => {
    setSelectAlldialogOpen(true); // Open the dialog
  };

  const handleDialogClose2 = () => {
    setSelectAlldialogOpen2(false); // Close the dialog
  };

  const handleDialogOpen2 = () => {
    setSelectAlldialogOpen2(true); // Open the dialog
  };
  const handleSave = async () => {
    if (!selectedRows.size) return;

    try {
      const updatedData = [];

      filteredData.forEach((row) => {
        if (selectedRows.has(row['Emp ID'])) {
          const empId = row['Emp ID'];
          const latestTotalComp = Number(row['Current Total Comp']) || 0;
          const previousIncrementPercentage =
            Number(row['Final Increment %']) || 0;
          const previousIncrementAmount =
            Number(row['Final Increment Amount']) || 0;

          const inputIncrementPercentage = Number(incrementper[empId]) || 0;
          const inputIncrementAmount = Number(increment[empId]) || 0;

          let calculatedPercentage = previousIncrementPercentage;
          let calculatedAmount = previousIncrementAmount;
          let totalCompensation = latestTotalComp;

          if (inputIncrementPercentage === 0 && inputIncrementAmount === 0) {
            calculatedPercentage = 0;
            calculatedAmount = 0;
            totalCompensation = 0;
          } else {
            if (
              inputIncrementPercentage > 0 &&
              inputIncrementPercentage !== previousIncrementPercentage
            ) {
              calculatedPercentage = inputIncrementPercentage;
              calculatedAmount = (calculatedPercentage / 100) * latestTotalComp;
              totalCompensation = latestTotalComp + calculatedAmount;
            }

            if (
              inputIncrementAmount > 0 &&
              inputIncrementAmount !== previousIncrementAmount
            ) {
              calculatedAmount = inputIncrementAmount;
              calculatedPercentage = (calculatedAmount / latestTotalComp) * 100;
              totalCompensation = latestTotalComp + calculatedAmount;
            }
          }

          incrementper[empId] = calculatedPercentage.toFixed(2);
          increment[empId] = calculatedAmount.toFixed(2);

          const finalTotalComp = totalCompensation.toFixed(2);

          const updatedRow = {
            'Emp ID': empId,
            'Change Accepted by Review Committee':
              HRValue[empId] || row['Change Accepted by Review Committee'],
            'Final Increment %': calculatedPercentage.toFixed(2),
            'Final Increment Amount': calculatedAmount.toFixed(2),
            'Final Total Comp': finalTotalComp,
            'Review Committee Comments':
              HrComments[empId] || row['Review Committee Comments'],
          };

          updatedData.push(updatedRow);
        }
      });

      if (updatedData.length === 0) {
        setSnackbarMessage('No valid rows to update.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      await dispatch(updateIncrementData(updatedData));

      if (PMS_ROLE === 'vp') {
        await dispatch(fetchFilteredIncrementData([fullName]));
      } else if (PMS_ROLE === 'vp-') {
        await dispatch(fetchFilteredIncrementData(vpArray));
      } else if (PMS_ROLE === 'vp+') {
        await dispatch(fetchTagFilteredIncrementData());
      } else {
        await dispatch(fetchIncrementData());
      }

      setSnackbarMessage('Data saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating increment data:', error);
      setSnackbarMessage(
        'There was an error updating the data. Please try again.'
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSaveVp = async () => {
    if (!selectedRows.size) return;

    try {
      const updatedData = [];

      filteredData.forEach((row) => {
        if (selectedRows.has(row['Emp ID'])) {
          const empId = row['Emp ID'];
          const updatedRow = {
            'Emp ID': empId,
            'BU Head Comments': VpComments[empId] || row['BU Head Comments'],
            'Initial BU Head Approval': VpInitialValues[empId] || row.check,
            'Final BU Head Approval': FinalVpAprroval[empId] || row.check,
          };

          // Set 'Final Increment Status' to 'Completed' only if 'Final BU Head Approval' is 'Accepted'

          updatedData.push(updatedRow);
        }
      });

      if (updatedData.length === 0) {
        setSnackbarMessage('No valid rows to update.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      await dispatch(updateIncrementData(updatedData));

      if (PMS_ROLE === 'vp') {
        await dispatch(fetchFilteredIncrementData([fullName]));
      } else if (PMS_ROLE === 'vp-') {
        await dispatch(fetchFilteredIncrementData(vpArray));
      } else if (PMS_ROLE === 'vp+') {
        await dispatch(fetchTagFilteredIncrementData());
      } else {
        await dispatch(fetchIncrementData());
      }

      setSnackbarMessage('Data saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating increment data:', error);
      setSnackbarMessage(
        'There was an error updating the data. Please try again.'
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSaveBuHead = async () => {
    if (!selectedRows.size) return;

    try {
      const updatedData = [];

      filteredData.forEach((row) => {
        if (selectedRows.has(row['Emp ID'])) {
          const empId = row['Emp ID'];
          let updatedRow = {
            'Emp ID': empId,
          };

          if (row['Initial BU Head Approval'] === 'Proposed') {
            updatedRow['Initial BU Head Approval'] = 'Proposed';
            updatedRow['Initial Increment Status'] = 'Completed';
          } else {
            updatedRow['Initial BU Head Approval'] = 'Approved';
            updatedRow['Initial Increment Status'] = 'Completed';
            updatedRow['Final BU Head Approval'] = 'Accepted';
            updatedRow['Final Increment Status'] = 'Completed';
          }

          updatedData.push(updatedRow);
        }
      });

      if (updatedData.length === 0) {
        setSnackbarMessage('No valid rows to update.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      await dispatch(updateIncrementData(updatedData));

      if (PMS_ROLE === 'vp') {
        await dispatch(fetchFilteredIncrementData([fullName]));
      } else if (PMS_ROLE === 'vp-') {
        await dispatch(fetchFilteredIncrementData(vpArray));
      } else if (PMS_ROLE === 'vp+') {
        await dispatch(fetchTagFilteredIncrementData());
      } else {
        await dispatch(fetchIncrementData());
      }

      setSnackbarMessage('BU Head Approval saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating BU Head Approval data:', error);
      setSnackbarMessage(
        'There was an error updating the data. Please try again.'
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async () => {
    if (!selectedRows.size) return;

    try {
      const updatedData = [];
      // Iterate over the filteredData (which should be the rows displayed)
      filteredData.forEach((row) => {
        if (selectedRows.has(row['Emp ID'])) {
          const empId = row['Emp ID'];
          // Directly use the frontend values for the initial approval
          const initialApproval = VpInitialValues[empId] || row.check; // Use local state (frontend) for Initial Approval
          const incrementStatus = 'Completed'; // Set Increment Status as 'Completed' for all selected rows

          // Prepare the updated row data, without Final Approval
          const updatedRow = {
            'Emp ID': empId,
            'BU Head Comments': VpComments[empId] || row['BU Head Comments'], // Use frontend value or fallback
            'Initial BU Head Approval': initialApproval,
            'Initial Increment Status': incrementStatus, // Set Increment Status as 'Completed'
            'Final BU Head Approval': FinalVpAprroval[empId] || row.check,
          };
          if (updatedRow['Final BU Head Approval'] === 'Accepted') {
            updatedRow['Final Increment Status'] = 'Completed';
          }
          updatedData.push(updatedRow);
        }
      });

      // If no valid rows to update, show an error message
      if (updatedData.length === 0) {
        setSnackbarMessage('No valid rows to update.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      // Dispatch the updates
      await dispatch(updateIncrementData(updatedData));

      // Fetch the filtered increment data based on the role
      if (PMS_ROLE === 'vp') {
        await dispatch(fetchFilteredIncrementData([fullName]));
      } else if (PMS_ROLE === 'vp-') {
        await dispatch(fetchFilteredIncrementData(vpArray));
      } else if (PMS_ROLE === 'vp+') {
        await dispatch(fetchTagFilteredIncrementData());
      } else {
        await dispatch(fetchIncrementData());
      }

      // Reset selected rows after saving
      // setSelectedRows(new Set());

      // Show success message
      setSnackbarMessage('Data saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating increment data:', error);
      setSnackbarMessage(
        'There was an error updating the data. Please try again.'
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleVpChange = (empId, value) => {
    setVpComments((prev) => ({...prev, [empId]: value}));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterClick = (event, column) => {
    setActiveColumn(column);
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setActiveColumn('');
  };

  const handleFilterSelect = (value) => {
    setColumnFilters((prev) => ({
      ...prev,
      [activeColumn]: value,
    }));

    // If the selected filter is "Final BU Head Tagging", update the selectedVP state and display the corresponding BU Budget
    if (activeColumn === 'Final BU Head Tagging') {
      const selectedRow = incrementData.find(
        (row) => row['Final BU Head Tagging'] === value
      );
      setSelectedVP(value); // Set the selected VP
      setSelectedBudget(selectedRow?.['BU Head Budget'] || null); // Set budget or null
    }

    handleFilterClose();
  };

  const handleClearFilter = (column) => {
    setColumnFilters((prev) => {
      const newFilters = {...prev};
      delete newFilters[column];
      // If the "Final BU Head Tagging" filter is cleared, reset selectedVP and selectedBudget
      if (column === 'Final BU Head Tagging') {
        setSelectedVP('');
        setSelectedBudget(null);
      }
      return newFilters;
    });
  };

  const handleClearAllFilters = () => {
    setColumnFilters({});
    setSelectedVP('');
    setSelectedBudget(null);
  };

  const handleFreezePane = (column) => {
    setFrozenColumn((prev) => {
      if (prev.includes(column)) {
        return prev.filter((col) => col !== column); // Unfreeze if already frozen
      } else {
        return [...prev, column]; // Freeze if not already frozen
      }
    });
  };

  const handleColumnVisibilityChange = (column) => {
    // Toggle the column visibility state
    setColumnVisibility((prevState) => ({
      ...prevState,
      [column]: prevState[column] === false ? true : false, // Toggle the value
    }));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Handle row selection
  const handleRowSelect = (empId) => {
    const newSelectedRows = new Set(selectedRows);
    newSelectedRows.has(empId)
      ? newSelectedRows.delete(empId)
      : newSelectedRows.add(empId);
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    if (selectedRows.size === filteredData.length) {
      setSelectedRows(new Set());
    } else {
      const allEmpIds = new Set(filteredData.map((row) => row['Emp ID']));
      setSelectedRows(allEmpIds);
    }
  };

  // Handle select all rows

  const handleVpInitialValueChange = (empId, value) => {
    setVpInitialValues((prev) => ({...prev, [empId]: value}));
  };

  const handleHRValueChange = (empId, value) => {
    setHRValue((prev) => ({...prev, [empId]: value}));
  };

  const handleincrement = (empId, value) => {
    setincrement((prev) => ({...prev, [empId]: value}));
  };

  const handleincrementper = (empId, value) => {
    setincrementper((prev) => ({...prev, [empId]: value}));
  };

  const handleGoBack = () => {
    navigate('/pms-page'); // Redirect to the main page or login page
  };

  const handlecomp = (empId, value) => {
    setcomp((prev) => ({...prev, [empId]: value}));
  };

  const handlehrcomments = (empId, value) => {
    setHrComments((prev) => ({...prev, [empId]: value}));
  };

  const handleFinalVpAprroval = (empId, value) => {
    setFinalVpArroval((prev) => ({...prev, [empId]: value}));
  };
  return (
    <Box className='main-container'>
      {/* AppBar at the top */}
      <AppBarComponent
        searchText={searchText}
        setSearchText={setSearchText}
        columnFilters={columnFilters}
        handleClearFilter={handleClearFilter}
        handleClearAllFilters={handleClearAllFilters}
        handleDialogOpen={handleDialogOpen}
        selectedVP={selectedVP}
        selectedBudget={selectedBudget}
        handleGoBack={handleGoBack}
        handleDownloadExcel={handleDownloadExcel}
      />

      <Box className='content-container'>
        {/* Filter component on the left side */}
        <Box className='filter-container'>
          <FilterComponent
            columnNames={columnNames}
            columnFilters={columnFilters}
            totalSum={totalSum}
            handleClearFilter={handleClearFilter}
            selectedVP={selectedVP}
            setSelectedVP={setSelectedVP}
            selectedBudget={selectedBudget}
            handleFilterClick={handleFilterClick}
            anchorEl={anchorEl}
            handleFilterClose={handleFilterClose}
            handleFilterSelect={handleFilterSelect}
            activeColumn={activeColumn}
            getUniqueValues={getUniqueValues}
            avgRateIncrementPercentage={avgRateIncrementPercentage}
          />
        </Box>

        {/* Table component on the right side */}
        <Box className='table-container'>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              className='snackbar-alert'
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          {/* Table scroll container */}
          <Box className='table-scroll-container'>
            <TableComponent
              filteredData={filteredData}
              columnNames={columnNames}
              columnVisibility={columnVisibility}
              frozenColumn={frozenColumn}
              page={page}
              rowsPerPage={rowsPerPage}
              handleSelectAll={handleSelectAll}
              handleRowSelect={handleRowSelect}
              handleVpInitialValueChange={handleVpInitialValueChange}
              handleHRValueChange={handleHRValueChange}
              handleincrement={handleincrement}
              handleincrementper={handleincrementper}
              handlecomp={handlecomp}
              handleVpChange={handleVpChange}
              handleFinalVpAprroval={handleFinalVpAprroval}
              handlehrcomments={handlehrcomments}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              selectedRows={selectedRows}
              VpInitialValues={VpInitialValues}
              VpComments={VpComments}
              HrComments={HrComments}
              HRValue={HRValue}
              increment={increment}
              incrementper={incrementper}
              IncrementStatus={IncrementStatus}
              comp={comp}
              FinalVpAprroval={FinalVpAprroval}
              handleFreezePane={handleFreezePane}
              handleFilterClick={handleFilterClick}
            />
          </Box>

          {/* Bottom buttons */}
          <div className='bottom-buttons'>
            {['hr', 'review_committee'].includes(PMS_ROLE) && (
              <Button
                variant='contained'
                startIcon={<Save />}
                onClick={handleSave}
                disabled={loading}
                sx={{
                  marginBottom: {xs: 2, sm: 0},
                  backgroundColor: '#333333',
                  color: '#FFFFFF',
                  '&:hover': {backgroundColor: '#000000'},
                  width: '190px',
                  fontSize: '0.7rem',
                  height: '36px',
                  minHeight: '36px',
                  padding: '6px 6px',
                }}
              >
                Save as Draft
              </Button>
            )}
            {['vp', 'vp+', 'vp-'].includes(PMS_ROLE) && (
              <Button
                variant='contained'
                startIcon={<Save />}
                onClick={handleSaveVp}
                disabled={loading}
                sx={{
                  marginBottom: {xs: 2, sm: 0},
                  backgroundColor: '#333333',
                  color: '#FFFFFF',
                  '&:hover': {backgroundColor: '#000000'},
                  width: '190px',
                  fontSize: '0.7rem',
                  height: '36px',
                  minHeight: '36px',
                  padding: '6px 6px',
                }}
              >
                Save as Draft
              </Button>
            )}

            <Button
              variant='contained'
              startIcon={<CheckCircleOutlineOutlinedIcon />}
              onClick={handleDialogOpen1} // Open the dialog on button click
              disabled={
                loading || ['hr', 'review_committee'].includes(PMS_ROLE)
              }
              sx={{
                marginBottom: {xs: 2, sm: 0},
                backgroundColor: 'Gray',
                color: '#FFFFFF',
                '&:hover': {backgroundColor: '#5A5A5A'},
                width: '190px',
                fontSize: '0.7rem',
                height: '36px',
                minHeight: '36px',
                padding: '6px 6px',
              }}
            >
              Approve Selection
            </Button>

            <Button
              variant='contained'
              endIcon={<Send />}
              onClick={handleDialogOpen2}
              disabled={
                loading || ['hr', 'review_committee'].includes(PMS_ROLE)
              }
              sx={{
                backgroundColor: '#F7901D',
                color: '#FFFFFF',
                '&:hover': {backgroundColor: '#D6791A'},
                width: '190px',
                fontSize: '0.7rem',
                height: '36px',
                minHeight: '36px',
                padding: '6px 6px',
              }}
            >
              Approve & Submit
            </Button>
          </div>

          <Dialog
            open={SelectAlldialogOpen}
            onClose={handleDialogClose1}
            fullWidth
            maxWidth='sm'
            className='dialog-container'
            sx={{
              borderRadius: '12px',
              padding: '20px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
            }}
          >
            <DialogContent sx={{padding: '4px 15px 15px'}}>
              <Typography
                variant='body1'
                sx={{
                  color: '#555',
                  lineHeight: '1.5',
                }}
              >
                <strong style={{fontSize: '1.2rem'}}>
                  Confirmation Required: Approve Selection
                </strong>
                <br />
                <br />
                Use this option
                <strong>
                  {' '}
                  only for employees whose compensation recommendations by the
                  Review Committee you fully accept
                </strong>{' '}
                without proposing any changes.
                <br />
                <br />
                By clicking this button, you will approve all selections,
                updating:
                <br />
                <br />
                <strong>"Initial BU Head Approval"</strong> to{' '}
                <strong>"Approved"</strong> (Only if it hasn't been saved as
                Proposed earlier)
                <br />
                <strong>"Final BU Head Approval"</strong> to{' '}
                <strong>"Accepted" </strong>(Only if the Initial BU Head
                Approval has been Approved)
                <br />
                <br />
                <span style={{color: 'red', fontWeight: 'bold'}}>
                  ⚠️ Note:
                </span>{' '}
                Once confirmed, no further edits will be possible. Please review
                your selections carefully before proceeding.
              </Typography>
            </DialogContent>

            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end', // Align the buttons to the right
                padding: '8px 10px',
              }}
            >
              <Button
                onClick={handleDialogClose1}
                sx={{
                  backgroundColor: '#F7901D',
                  color: '#FFFFFF',
                  border: '1px solid #F7901D',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#FF7A00',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                  },
                }}
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSaveBuHead(); // Handle approval logic
                  handleDialogClose1(); // Close the dialog
                }}
                sx={{
                  backgroundColor: '#333333',
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#000000',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                  },
                }}
                variant='contained'
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={SelectAlldialogOpen2}
            onClose={handleDialogClose2}
            fullWidth
            maxWidth='sm'
            className='dialog-container'
            sx={{
              borderRadius: '12px',
              padding: '20px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
            }}
          >
            <DialogContent sx={{padding: '4px 15px 15px'}}>
              <Typography
                variant='body1'
                sx={{
                  color: '#555',
                  lineHeight: '1.5',
                }}
              >
                <strong>Confirmation Required: Approve & Submit</strong>
                <br />
                <br />
                By clicking this button, you will submit the selected value, and
                the
                <strong> "Initial BU Head Approval" </strong> and
                <strong> "BU Head Comments" </strong> columns will be locked. No
                further edits to these fields will be possible after submission.
                <br />
                <br />
                <span style={{color: 'red', fontWeight: 'bold'}}>
                  ⚠️ Note:
                </span>{' '}
                Please review your selections carefully before proceeding and If
                the <strong>Final BU Head Approval</strong> is accepted, it will
                be locked, and no further changes can be made.
              </Typography>
            </DialogContent>

            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end', // Align the buttons to the right
                padding: '8px 10px',
              }}
            >
              <Button
                onClick={handleDialogClose2}
                sx={{
                  backgroundColor: '#F7901D',
                  color: '#FFFFFF',
                  border: '1px solid #F7901D',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#FF7A00',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                  },
                }}
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmit(); // Handle approval logic
                  handleDialogClose2(); // Close the dialog
                }}
                sx={{
                  backgroundColor: '#333333',
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#000000',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                  },
                }}
                variant='contained'
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for column selection */}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            fullWidth
            maxWidth='sm'
            className='dialog-container'
          >
            <DialogTitle className='dialog-title'>
              Select Columns to Display
            </DialogTitle>

            <DialogContent>
              <Box className='dialog-content'>
                {columnNames.map((column) => (
                  <Box key={column} className='column-option'>
                    <Checkbox
                      checked={columnVisibility[column] !== false}
                      onChange={() => handleColumnVisibilityChange(column)}
                      size='medium'
                      className='checkbox'
                    />
                    <Box component='span' className='column-name'>
                      {column}
                    </Box>
                  </Box>
                ))}
              </Box>
            </DialogContent>

            <DialogActions className='dialog-actions'>
              <Button
                onClick={handleDialogClose}
                color='primary'
                variant='contained'
                className='close-button'
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Filter Dropdown */}
        </Box>
      </Box>
    </Box>
  );
};

export default IncrementMatrix;
