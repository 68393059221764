import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

const FilterComponent = ({
  columnNames,
  columnFilters,
  anchorEl,
  totalSum,
  handleFilterClose,
  handleFilterSelect,
  activeColumn,
  getUniqueValues,
  avgRateIncrementPercentage,
  handleClearFilter,
  selectedVP,
  selectedBudget,
  handleFilterClick,
}) => {
  const theme = useTheme();
  const filterableColumns = [
    'Final BU Head Tagging',
    'Review CP',
    'Tagging',
    'Initial BU Head Approval',
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between', // Adjusts space between elements
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '16px',
            flexWrap: 'wrap',
            flexGrow: 1,
          }}
        >
          {filterableColumns.map((column) => (
            <Button
              key={column}
              variant='outlined'
              size='small'
              onClick={(event) => handleFilterClick(event, column)}
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 16px',
                width: '140px',
                fontFamily: 'Nanum Myeongjo, serif',
                fontWeight: 700,
                color: '#fff',
                backgroundColor: '#1c1c1c',
                borderColor: '#e0e0e0',
                transition: 'all 0.3s ease',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontSize: '0.7rem',
                lineHeight: '1.2',
                maxWidth: '100%',
                '&:hover': {
                  backgroundColor: '#2a2a2a',
                  borderColor: '#f5f5f5',
                  transform: 'scale(1.08)',
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              {columnFilters[column] || ` ${column}`}
            </Button>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center', // Align vertically with the text
            flexWrap: 'wrap',
            flexGrow: 0,
            marginLeft: '20px', // Adding a bit of space between filter and text
          }}
        >
          {[
            'BU Budget',
            'Budget Utilized',
            'Budget Remaining',
            'Avg. Increment %',
          ].map((label, index) => (
            <Typography
              key={index}
              variant='body2'
              sx={{
                fontWeight: 600,
                color: '#444', // This is the default color (black) for most labels
                fontFamily: 'Nanum Myeongjo, serif',
                fontSize: '0.85rem',
              }}
            >
              <strong>{label}:</strong>
              <span
                style={{
                  fontFamily: 'Nanum Myeongjo, serif',
                  color:
                    label === 'Budget Remaining'
                      ? selectedBudget - totalSum < 0
                        ? 'red' // "Budget Remaining" turns red when the remaining is negative
                        : 'darkgreen' // "Budget Remaining" turns dark green when positive
                      : '#444', // All other labels stay black
                  padding: '0 4px',
                  borderRadius: '3px',
                  fontWeight: '700',
                }}
              >
                {label === 'BU Budget'
                  ? selectedBudget || 'N/A'
                  : label === 'Budget Utilized'
                  ? Math.floor(totalSum) // Removing decimals for Budget Utilized
                  : label === 'Budget Remaining'
                  ? Math.floor(selectedBudget - totalSum) // Removing decimals for Budget Remaining
                  : avgRateIncrementPercentage}
              </span>
            </Typography>
          ))}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        PaperProps={{
          className: 'filter-menu',
          sx: {
            maxWidth: '260px',
            maxHeight: '300px',
            overflowY: 'auto',
            padding: '4px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Typography
          variant='subtitle1'
          className='filter-menu-title'
          sx={{fontSize: '0.9rem', marginBottom: '4px'}}
        >
          Filter by:
        </Typography>
        <Divider className='filter-menu-divider' sx={{margin: '4px 0'}} />
        <MenuItem
          onClick={() => handleFilterSelect('All')}
          className='filter-menu-item'
          sx={{fontSize: '0.89rem', padding: '4px 10px'}}
        >
          All
        </MenuItem>
        {activeColumn &&
          getUniqueValues(activeColumn).map((value) => (
            <MenuItem
              key={value}
              onClick={() => handleFilterSelect(value)}
              className='filter-menu-item'
              sx={{fontSize: '0.85rem', padding: '6px 12px'}}
            >
              {value}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
export default FilterComponent;
