import {AppBar} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React from 'react';
import logoImage from '../../components/Assets/correct_logo.png'; // Adjust the path as needed
import paperTexture from '../Assets/texture.jpg';

const Logo = ({isMobile}) => {
	const theme = useTheme();

	return (
		<AppBar
			position='static'
			color='transparent'
			elevation={0}
			sx={{
				backgroundColor: 'white',
				backgroundImage: `url(${paperTexture})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				height: '70px',
				transition: 'box-shadow 0.3s ease, background-color 0.3s ease',
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Initial subtle shadow
				'&:hover': {
					boxShadow: '0 15px 35px rgba(0, 0, 0, 0.4)', // Darker and more pronounced shadow on hover
					backgroundColor: theme.palette.grey[100], // Subtle background color change on hover
				},
			}}
		>
			<img
				src={logoImage}
				alt='Tiger Analytics Logo'
				style={{
					height: isMobile ? '40px' : '60px',
					position: 'absolute', // This ensures the logo is placed at a fixed position
					top: '5px', // Distance from the top of the page
					left: '50px', // Distance from the left of the page
				}}
			/>
		</AppBar>
	);
};

export default Logo;
