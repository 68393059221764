import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppraisalIcon from '@mui/icons-material/AssessmentOutlined'; // Example icon for Appraisal
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
// import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import {Grid, Link, Tooltip} from '@mui/material';
import {StyledEngineProvider} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as Onedataicon} from '../Assets/Onedataicon.svg';
import profile_picture from '../Assets/profile_picture.png';
import EditMenu from '../EditMenu/EditMenu';
import Glossary from '../Glossary/Glossary';
import CustomModal from '../Modal/CustomModal';
import {getRefreshDate} from '../Utilities/apis/PowerBiApis/getReportAccessToken';
import classes from './Header.module.css';

const Header = () => {
  let user = useSelector((state) => state.PowerBi.user);
  const defaultReport = useSelector((state) => state.PowerBi.defaultReport);
  const location = useLocation();
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [refreshDate, setRefreshDate] = useState(null);
  const [hoverGlossary, sethoverGlossary] = useState(false);
  const [hoverFeedback, sethoverFeedback] = useState(false);
  const [hoverAdmin, sethoverAdmin] = useState(false);
  const [hoverRemarks, sethoverRemarks] = useState(false);
  const [hoverNotification, sethoverNotification] = useState(false);
  const [hoverAppraisal, sethoverAppraisal] = useState(false); // Hover state for Appraisal icon
  const [hoverIncrement, sethoverIncrement] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEditProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEditProfileClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleGlossary = () => {
    setOpen(true);
  };
  const Date =
    refreshDate !== 'undefined' &&
    refreshDate !== null &&
    refreshDate !== '' &&
    refreshDate !== undefined
      ? moment(refreshDate).day() === 1
        ? moment(refreshDate)
            .subtract(3, 'days')
            .format('DD-MM-YYYY')
            .split('-')
        : moment(refreshDate)
            .subtract(1, 'days')
            .format('DD-MM-YYYY')
            .split('-')
      : '';
  const Month = Date !== '' ? moment.months(parseInt(Date[1]) - 1) : '';

  useEffect(() => {
    if (Object.keys(defaultReport).length !== 0) {
      getRefreshDate([
        defaultReport.PBIREPORTID,
        defaultReport.PBIWORKSPACEID,
      ]).then((date) => setRefreshDate(date));
    }
  }, [defaultReport]);

  return (
    <StyledEngineProvider injectFirst>
      <Grid
        container
        position='fixed'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        className={classes.appbar}
      >
        <Grid
          container
          item
          className={classes.leftHeader}
          md={6}
          xl={6}
          xs={1}
        >
          <Grid item md={3} xl={3} xs={3}>
            <Onedataicon className={classes.onedataicon} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.rightHeader}
          md={6}
          xl={6}
          xs={11}
        >
          <Grid
            item
            container
            spacing={2}
            md={6}
            xl={6}
            xs={6}
            className={classes.iconsContainer}
          >
            {/* New Appraisal Icon */}
            {['cp', 'vp', 'hr', 'review_committee', 'vp+', 'vp-'].includes(
              user?.PMS_ACCESS
            ) && (
              <Grid item>
                <Tooltip title={'PMS Process'}>
                  <AppraisalIcon
                    onClick={
                      location.pathname.includes('/pms-page')
                        ? null
                        : () => Navigate('/pms-page')
                    }
                    className={
                      hoverAppraisal === true ||
                      location.pathname.includes('/pms-page')
                        ? classes.remarksHover
                        : classes.remarks
                    }
                    onMouseEnter={() => sethoverAppraisal(true)}
                    onMouseLeave={() => sethoverAppraisal(false)}
                  />
                </Tooltip>
                {/* <Tooltip title={'Increment Dashboard'}>
                  <FormatIndentIncreaseIcon
                    onClick={
                      location.pathname.includes('/increment-matrix')
                        ? null
                        : () => Navigate('/increment-matrix')
                    }
                    className={
                      hoverIncrement === true ||
                      location.pathname.includes('/increment-matrix')
                        ? classes.remarksHover
                        : classes.remarks
                    }
                    onMouseEnter={() => sethoverIncrement(true)}
                    onMouseLeave={() => sethoverIncrement(false)}
                  />
                </Tooltip> */}
              </Grid>
            )}

            {/* Existing Projections Remarks Icon */}
            <Grid item>
              <Tooltip title={'Projections Remarks'}>
                {user?.ISADMIN && (
                  <RequestPageIcon
                    onClick={
                      location.pathname.includes('/remarks')
                        ? null
                        : () => Navigate('/remarks')
                    }
                    className={
                      hoverRemarks === true ||
                      location.pathname.includes('/remarks')
                        ? classes.remarksHover
                        : classes.remarks
                    }
                    onMouseEnter={() => sethoverRemarks(true)}
                    onMouseLeave={() => sethoverRemarks(false)}
                  >
                    All ProjectionsRemarks
                  </RequestPageIcon>
                )}
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={'AdminScreen'}>
                {user?.ISADMIN && (
                  <AdminPanelSettingsIcon
                    onClick={
                      location.pathname.includes('/admin')
                        ? null
                        : () => Navigate('/admin')
                    }
                    className={
                      hoverAdmin === true ||
                      location.pathname.includes('/admin')
                        ? classes.adminButtonHover
                        : classes.adminbutton
                    }
                    onMouseEnter={() => sethoverAdmin(true)}
                    onMouseLeave={() => sethoverAdmin(false)}
                  >
                    Admin
                  </AdminPanelSettingsIcon>
                )}
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  location.pathname.includes('/admin')
                    ? 'disabled for admin'
                    : 'Glossary'
                }
              >
                <FeaturedPlayListOutlinedIcon
                  className={
                    open || hoverGlossary === true
                      ? classes.glossaryIconHover
                      : classes.glossaryIcon
                  }
                  onClick={
                    location.pathname.includes('/admin') ? null : handleGlossary
                  }
                  onMouseEnter={() => sethoverGlossary(true)}
                  onMouseLeave={() => sethoverGlossary(false)}
                />
              </Tooltip>
            </Grid>
            <CustomModal open={open}>
              <>
                <Glossary handleClose={handleClose} />
              </>
            </CustomModal>
            <Grid item>
              <Link
                href='https://docs.google.com/forms/d/e/1FAIpQLSeie5sdqfgjbx9gKBdGt6ToOqarPJ8lkeIol5QVU5cdGleVVQ/viewform'
                target='_blank'
                rel='noopener'
              >
                <Tooltip title={'Feedback'}>
                  <RateReviewOutlinedIcon
                    className={
                      hoverFeedback === true
                        ? classes.feedbackIconHover
                        : classes.feedbackIcon
                    }
                    onMouseEnter={() => sethoverFeedback(true)}
                    onMouseLeave={() => sethoverFeedback(false)}
                  />
                </Tooltip>
              </Link>
            </Grid>
            <Grid item>
              <NotificationsNoneOutlinedIcon
                className={
                  hoverNotification === true
                    ? classes.notificationIconHover
                    : classes.notificationIcon
                }
                onMouseEnter={() => sethoverNotification(true)}
                onMouseLeave={() => sethoverNotification(false)}
              />
            </Grid>
            <Grid item>
              <img
                className={classes.picture}
                alt={user.picture ? 'pro' : ''}
                src={user?.picture || profile_picture}
                onClick={handleEditProfile}
              />
              <EditMenu
                anchorEl={anchorEl}
                handleEditProfileClose={handleEditProfileClose}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.rightHeaderText}>
            <Typography className={classes.lastUpdated}>
              {Date.length !== 0
                ? `Data as of : ${Date[0]} ${Month?.slice(0, 3)}, ${Date[2]}`
                : 'Refresh Not Done'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledEngineProvider>
  );
};

export default Header;
