import {Logout} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import axios from 'axios';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Logo from '../../components/Userprofile/logoImage';
import config from '../../config';
import './PmsFileUpload.css';
const PmsFileUpload = () => {
  const [files, setFiles] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadAlertOpen, setUploadAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [selectedTab, setSelectedTab] = useState(0);
  const [isFormatDialogOpen, setIsFormatDialogOpen] = useState(false);
  const [alertTimeout, setAlertTimeout] = useState(null);

  const navigate = useNavigate();

  const fileNames = [
    {
      name: 'HR Onshore Comp Master',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/130VIFwf1JQkBbJ_Y9u7SQg_byVuuz7JG/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'Perform Yard Employee Dump',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1JofKJUM0440cGuCohYQcraOfrSzL-VIq/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'PY-BU_Head Completion Status',
      format: '.xls',
      link: 'https://docs.google.com/spreadsheets/d/1MKMsGyytUDSM1MRkY4PbS_AXcFwclIdN/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'Utilisation Sheet',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1t79F0uKdMuEersEnNVJZ0n8w6Siq9HPm/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'Perform Yard Criticality and Promotion',
      format: '.xls',
      link: 'https://docs.google.com/spreadsheets/d/1Gdtyu4R8X3zK7ZOCTtf8BUkNu9gg834j/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'PMS Calibration Master Input',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1leh08-Bj5-LzfAKEt-BmfhWeSY_ph63C/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'Designation Eligibility',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1SFCkDTyFsW7Eb6s_Ifuz_tmgqeViALpd/edit?usp=drive_link&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'Grouping Matrix',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1vVQWYqU03SwoLDQQ8X0L8RASlXsTxaCM/edit?usp=sharing&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'Increment Matrix',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1dQ5toD7o7IxaEPHJOekIOAC7_FMI66Yx/edit?usp=drive_link&ouid=114540510377050564088&rtpof=true&sd=true',
    },
    {
      name: 'BU Head/VP Budget',
      format: '.xlsx',
      link: 'https://docs.google.com/spreadsheets/d/1UaDLRAr8tFSTc7obPLlLzyPH7K0mi2ls/edit?usp=drive_link&ouid=114540510377050564088&rtpof=true&sd=true',
    },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileExtension = selectedFile?.name.split('.').pop().toLowerCase();

    if (fileExtension === 'xlsx' || fileExtension === 'xls') {
      setFiles(selectedFile);
      setUploadMessage('');
    } else {
      setFiles(null);
      setUploadMessage(
        'This file format is not supported. Please upload an Excel file.'
      );
      setAlertType('error');
      setUploadAlertOpen(true);
    }
  };

  const handleFileUpload = async () => {
    if (!files) {
      setUploadMessage('Please select a file first');
      setAlertType('error');
      setUploadAlertOpen(true);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', files);

    const isPmsFile =
      fileNames[selectedTab].name === 'PMS Calibration Master Input'
        ? 'true'
        : 'false';
    formData.append('isPmsFile', isPmsFile);

    const islookup =
      fileNames[selectedTab].name === 'Designation Eligibility' ||
      fileNames[selectedTab].name === 'Grouping Matrix'
        ? 'true'
        : 'false';
    formData.append('islookup', islookup);
    const isIncrement =
      fileNames[selectedTab].name === 'Increment Matrix' ? 'true' : 'false';
    formData.append('isIncrement', isIncrement);

    const isBuBudget =
      fileNames[selectedTab].name === 'BU Head/VP Budget' ? 'true' : 'false';
    formData.append('isBuBudget', isBuBudget);
    try {
      const response = await axios.post(`${config.apiURL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadMessage(response.data.message);
      setAlertType('success');
    } catch (err) {
      console.error('Upload error:', err);
      setUploadMessage(err.response?.data?.message || 'Failed to upload file');
      setAlertType('error');
    } finally {
      setLoading(false);
      setUploadAlertOpen(true);

      const timeoutId = setTimeout(() => {
        setUploadAlertOpen(false);
      }, 2000);
      setAlertTimeout(timeoutId);
    }
  };

  const handleUploadAlertClose = () => {
    if (alertTimeout) clearTimeout(alertTimeout);
    setUploadAlertOpen(false);
  };

  const handleGoBack = () => {
    navigate('/calibration-dashboard');
  };

  const toggleFormatDialog = () => {
    setIsFormatDialogOpen(!isFormatDialogOpen);
  };

  return (
    <>
      <div className='logo-container'>
        <Logo /> {/* Adjust the `isMobile` prop as needed */}
      </div>
      <div className='file-upload-container'>
        <div className='header'>
          <h1>File Upload System</h1>
        </div>
        <div className='header-buttons'>
          <IconButton
            color='inherit'
            sx={{
              p: 1,
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)', // Reduced glow effect
                transform: 'scale(1.1)',
              },
            }}
            onClick={handleGoBack}
          >
            <Logout />
          </IconButton>

          <button onClick={toggleFormatDialog} className='format-button'>
            File Formats
          </button>
          <button
            className='format-button'
            style={{
              position: 'fixed',
              top: '16px',
              right: '220px',
              zIndex: 1000,
            }}
            onClick={() => {
              window.open(
                'https://drive.google.com/file/d/1M9BQYV4dY_G7NkuR_qxnkbHsPQdA_vWM/view?usp=drive_link'
              );
            }}
          >
            User Guide
          </button>
        </div>

        <div className='content'>
          <div className='file-list'>
            <ul>
              {fileNames.map((file, index) => (
                <li
                  key={index}
                  className={`file-item ${
                    selectedTab === index ? 'selected' : ''
                  } ${
                    file.name === 'Increment Matrix' ||
                    file.name === 'BU Head/VP Budget'
                      ? 'special-highlight'
                      : file.name === 'PMS Calibration Master Input' ||
                        file.name === 'Designation Eligibility' ||
                        file.name === 'Grouping Matrix'
                      ? 'highlight'
                      : ''
                  }`}
                  onClick={() => {
                    setSelectedTab(index);
                    setFiles(null);
                  }}
                >
                  {file.name}
                </li>
              ))}
            </ul>
          </div>

          <div className='upload-panel'>
            <h2>Upload the File for: {fileNames[selectedTab].name}</h2>

            <div
              className='file-dropzone'
              onClick={() => document.getElementById('fileInput').click()}
            >
              <div className='upload-icon'>⬆️</div>
              <p>
                Drop your file here, or{' '}
                <span className='browse-text'>Browse</span> (Upload in .xlsx
                format only)
              </p>
              <input
                type='file'
                id='fileInput'
                onChange={handleFileChange}
                hidden
              />
            </div>

            {files && (
              <div className='file-info'>
                <span>{files.name}</span>
              </div>
            )}

            {loading ? (
              <div className='loading-indicator'>Uploading...</div>
            ) : (
              <button onClick={handleFileUpload} className='upload-button'>
                Upload File
              </button>
            )}
          </div>
        </div>

        {uploadAlertOpen && (
          <div
            className={`alert ${alertType}`}
            onClick={handleUploadAlertClose}
          >
            {uploadMessage}
          </div>
        )}

        {isFormatDialogOpen && (
          <div className='format-dialog'>
            <div className='format-dialog-content'>
              <h2>Supported File Formats</h2>
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Format</th>
                  </tr>
                </thead>
                <tbody>
                  {fileNames.map((file, index) => (
                    <tr key={index}>
                      <td>{file.name}</td>
                      <td>
                        <a
                          href={file.link}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Link
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                onClick={toggleFormatDialog}
                className='close-dialog-button'
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PmsFileUpload;
