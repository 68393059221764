import {
	Grid,
	Button,
	Typography,
	Autocomplete,
	TextField,
	Checkbox,
	Chip,
} from '@mui/material';
import classes from './UserReportForm.module.css';
import {useState, useEffect} from 'react';
import {StyledEngineProvider} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import addUser from '../../../components/Utilities/v2Apis/addUser';
import updateUser from '../../../components/Utilities/v2Apis/updateUser';
import {getUsers, getReportsByUser} from '../../../redux/adminReducer';
import {getAllUsersRefactoredApi} from '../../../components/Utilities/apis/getAllUsers';
import {CheckBoxOutlineBlank, CheckBox} from '@mui/icons-material';
import {
	getAllPBINamesApi,
	getAllPBIRoles,
} from '../../../components/Utilities/v2Apis/getUserReports';
import CloseIcon from '@mui/icons-material/Close';
import SearchAutoCompleteUsers from '../../../pages/UserReports/SearchAutoCompleteUsers';

const UserReportForm = (props) => {
	const {
		formdata,
		userId,
		handleAlert,
		handleSelectedUser,
		handleCloseModal,
		handleUserChange,
		rowdata,
	} = props;
	const [emailvalid, setEmailValid] = useState(true);
	const dispatch = useDispatch();
	// setting Local state for form Data
	const [formData, setFormData] = useState({});
	const [conditionChecks, setConditionChecks] = useState({});
	const [pbiRoleOptions, setPbiRoleOptions] = useState([]);
	const [pbiNameOptions, setPbiNameOptions] = useState([]);

	// Function to  Validate Email
	const isEmailValid = (email) => {
		let regex = /^[a-zA-Z.0-9-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (email.match(regex) && email.split('@')[1] === 'tigeranalytics.com') {
			return true;
		}
	};
	// Function to execute ONSubmit
	const onSubmit = async () => {
		// Code To check Empty and Null Values
		const isEmpty = Object.values(formData).some((x) => x === '' || x === null);

		if (!isEmpty && isEmailValid(formData.EmailID)) {
			try {
				userId
					? updateUser({formData, userId, conditionChecks}).then((res) => {
							handleAlert(res);
							if (res?.status === 200) {
								handleSelectedUser({});
								dispatch(getReportsByUser({}));
							}
							getAllUsersRefactoredApi().then((data) => {
								dispatch(getUsers(data));
							});
					  })
					: addUser({formData, conditionChecks}).then((res) => {
							handleAlert(res);
							handleSelectedUser({});
							if (res?.status === 200) {
								handleSelectedUser({});
								setFormData({});
								dispatch(getReportsByUser({}));
							}
							getAllUsersRefactoredApi().then((data) => {
								dispatch(getUsers(data));
							});
					  });
			} catch (err) {
				console.log(err);
			}
		}
	};
	const handleRemarks = (e) => {
		const val = e.target.value;
		setFormData(() => ({
			...formData,
			[e.target.name]: val,
		}));
	};
	// Functio to Handle Email Change
	const handleEmailChange = (e) => {
		const val = e.target.value;
		isEmailValid(val) ? setEmailValid(true) : setEmailValid(false);
		setFormData(() => ({
			...formData,
			[e.target.name]: val,
		}));
	};

	// Function For ONSelect Role
	const selectRole = (e, value) => {
		setFormData(() => ({
			...formData,
			RoleName: value,
		}));
		setConditionChecks({
			isPractice: value === 'Practice_Users' || false,
			isMaster: value === 'Master_Users' || false,
		});
	};
	const isChecked = (option) => {
		return formData?.PBIUserName.some(
			(selected) => selected.PBI_UserName === option.PBI_UserName
		);
	};
	const selectPBIRole = (e, value) => {
		let updatedValue = conditionChecks?.isPractice
			? value
			: [conditionChecks?.isMaster ? {PBI_RoleName: 'Master'} : value];
		setFormData(() => ({
			...formData,
			PBIRoleName: updatedValue,
		}));
	};
	const selectPBIName = (e, value) => {
		setFormData(() => ({
			...formData,
			PBIUserName: value,
		}));
	};

	useEffect(() => {
		getAllPBIRoles().then((result) => {
			setPbiRoleOptions(result);
		});
		getAllPBINamesApi().then((result) => {
			setPbiNameOptions(result);
		});
	}, []);
	useEffect(() => {
		setFormData({
			// FirstName: formdata?.FIRSTNAME ?? '',
			EmailID: formdata?.EMAILID ?? '',
			// LastName: formdata?.LASTNAME ?? null,
			RoleName: formdata?.PBI_ROLE ?? null,
			PBIRoleName: JSON.parse(formdata?.UserRoleMapping ?? null) ?? [],
			PBIUserName: JSON.parse(formdata?.UserRoleMapping ?? null) ?? [],
			remarks: formdata?.REMARKS,
		});
		setConditionChecks({
			isPractice: formdata?.PBI_ROLE === 'Practice_Users' || false,
			isMaster: formdata?.PBI_ROLE === 'Master_Users' || false,
		});
	}, [
		formdata?.EMAILID,
		// formdata?.FIRSTNAME,
		formdata?.PBI_ROLE,
		formdata?.REMARKS,
		formdata?.UserRoleMapping,
	]);

	return (
		<StyledEngineProvider injectFirst>
			<Grid
				container
				className={classes.modalContainer}
				spacing={2}
			>
				<Grid
					container
					className={classes.headerContainer}
					md={12}
					xl={12}
					xs={12}
				>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						style={{display: 'flex', justifyContent: 'left'}}
					>
						<Typography style={{fontWeight: 'bold'}}>
							{userId ? `Update User` : `Add User`}
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						md={6}
						xl={6}
						style={{display: 'flex', justifyContent: 'right'}}
					>
						<CloseIcon
							onClick={handleCloseModal}
							className={classes.closeButton}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={1}
					wrap='wrap'
					className={classes.formContainer}
				>
					<SearchAutoCompleteUsers
						handleUserChange={handleUserChange}
						rowdata={rowdata}
						selectedUser={formdata}
					/>
					<Grid
						item
						xs={12}
						md={4}
						xl={4}
						className={classes.nameField}
					>
						<TextField
							error={!emailvalid}
							helperText={emailvalid ? '' : 'Enter a valid email'}
							label='Email'
							variant='outlined'
							size='small'
							name='EmailID'
							autoComplete='off'
							value={formData.EmailID ? formData.EmailID : ''}
							onChange={handleEmailChange}
							className={classes.autoComplete}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						xl={4}
						className={classes.emailField}
					>
						<Autocomplete
							renderOption={(props, option) => (
								<Typography
									{...props}
									style={{fontSize: '12px'}}
								>
									{option}
								</Typography>
							)}
							options={[
								'Practice_Users',
								'Master_Users',
								'CP L1_Users',
								'CP L2_Users',
								'DP_Users',
								'TP_Users',
								'BU_Users',
								'FINOPS_Users',
							]}
							id='RoleName'
							autoHighlight
							value={formData?.RoleName ?? null}
							className={classes.autoComplete}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Role Group'
									variant='outlined'
									size='small'
									inputProps={{...params.inputProps, style: {fontSize: '16px'}}}
								/>
							)}
							onChange={(e, value) => selectRole(e, value)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						xl={4}
						className={classes.emailField}
					>
						<Autocomplete
							renderTags={(value, getTagProps) => (
								<div>
									{value.map((option, index) => (
										<Chip
											key={index}
											label={option?.PBI_RoleName}
											{...getTagProps({index})}
										/>
									))}
								</div>
							)}
							disableCloseOnSelect
							renderOption={(props, option) => (
								<Typography
									{...props}
									style={{fontSize: '12px'}}
									key={option.PBI_RoleName}
								>
									{`${option?.PBI_RoleName}`}
								</Typography>
							)}
							options={pbiRoleOptions}
							id='PBI_RoleName'
							isOptionEqualToValue={(option, value) =>
								option.PBI_RoleName === value.PBI_RoleName || value === ''
							}
							multiple={conditionChecks?.isPractice}
							getOptionLabel={(option) => option.PBI_RoleName ?? ''}
							autoHighlight
							value={
								conditionChecks?.isPractice
									? formData?.PBIRoleName ?? []
									: formData?.PBIRoleName?.[0] ?? {}
							}
							className={classes.autoComplete}
							renderInput={(params) => (
								<TextField
									{...params}
									label='PBIRoleName'
									variant='outlined'
									size='small'
									inputProps={{...params.inputProps, style: {fontSize: '16px'}}}
								/>
							)}
							onChange={(e, value) => selectPBIRole(e, value)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						xl={4}
						className={classes.emailField}
					>
						<Autocomplete
							disabled={
								conditionChecks?.isPractice || conditionChecks?.isMaster
							}
							renderOption={(props, option) => (
								<li
									{...props}
									style={{fontSize: '12px'}}
								>
									<Checkbox
										key={option.PBI_UserName}
										icon={<CheckBoxOutlineBlank />}
										checkedIcon={<CheckBox />}
										checked={isChecked(option)}
									/>
									{option?.PBI_UserName}
								</li>
							)}
							renderTags={(value, getTagProps) => {
								if (conditionChecks?.isPractice || conditionChecks?.isMaster) {
									return <span style={{height: '0.6rem'}}>{null}</span>;
								} else {
									return (
										<div>
											{value.map((option, index) => (
												<Chip
													key={index}
													label={option?.PBI_UserName}
													{...getTagProps({index})}
													style={{
														textOverflow: 'ellipsis', // Add ellipsis for long text
													}}
												/>
											))}
										</div>
									);
								}
							}}
							disableCloseOnSelect
							multiple
							options={pbiNameOptions}
							isOptionEqualToValue={(option, value) =>
								option.PBI_UserName === value.PBI_UserName || value === ''
							}
							id='PBIUserName'
							onChange={(e, value) => selectPBIName(e, value)}
							getOptionLabel={(option) => option.PBI_UserName ?? ''}
							value={formData?.PBIUserName ?? []}
							className={classes.autoComplete}
							renderInput={(params) => (
								<TextField
									{...params}
									label='PBI User Name'
									variant='outlined'
									size='small'
									inputProps={{...params.inputProps, style: {fontSize: '14px'}}}
								/>
							)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						xl={4}
						className={classes.nameField}
					>
						<TextField
							label='Remarks'
							variant='outlined'
							size='small'
							multiline
							maxRows={1}
							name='remarks'
							autoComplete='off'
							value={formData.remarks ? formData.remarks : ''}
							onChange={handleRemarks}
							className={classes.autoComplete}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Button
							onClick={onSubmit}
							style={{
								backgroundColor: '#F7901D',
								color: 'white',
								width: '25%',
								borderRadius: '1rem',
								textTransform: 'none',
							}}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</StyledEngineProvider>
	);
};

export default UserReportForm;
